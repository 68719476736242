@import "../../../assets/scss/magic";
.teams-vs-holder {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: flex-end;
  grid-column-gap: rem(24);
  &.interactive {
    grid-template-columns: 1fr auto 1fr auto;
  }
  > li {
    > small {
      display: flex;
      height: rem(42);
      width: rem(30);
      align-items: center;
      justify-content: center;
      font: rem(14) / rem(16) $f;
      opacity: 0.3;
    }
  }
}
.teams-actions-holder {
  display: flex;
  align-items: center;
  height: rem(42);
}
