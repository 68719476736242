@import "../../../assets/scss/magic";
.crs-holder {
  display: inline-flex;
  flex-direction: column;
  max-width: 100%;
  align-items: flex-start;
  user-select: none;
  font: rem(14) / rem(16) $f;
  transition: 400ms opacity, 400ms color;
  cursor: pointer;
  color: sc(b, 0.6);
  &:hover {
    color: sc(b);
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &.read-only {
    pointer-events: none;
  }
  &.c-confirm {
    @include over(primary, confirm);
  }
  .label-holder {
    max-width: 100%;
    display: inline-grid;
    vertical-align: top;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-row-gap: rem(8);
    &.has-label {
      grid-column-gap: rem(10);
    }
    @at-root {
      .lp-top#{&}, .lp-bottom#{&} {
        grid-template-columns: auto;
        grid-template-rows: auto auto;
      }
      .lp-bottom#{&}, .lp-right#{&} {
        .crs-component {
          order: 0;
        }
        .crs-label {
          order: 1;
        }
      }
      .la-start#{&} {
        .crs-item {
          text-align: start;
          justify-content: flex-start;
          align-items: flex-start;
        }
      }
      .la-center#{&} {
        .crs-item {
          text-align: center;
          justify-content: center;
          align-items: center;
        }
      }
      .la-end#{&} {
        .crs-item {
          text-align: end;
          justify-content: flex-end;
          align-items: flex-end;
        }
      }
    }
  }
  &.la-start {
    align-items: flex-start;
  }
  &.la-center {
    align-items: center;
  }
  &.la-end {
    align-items: flex-end;
  }
  &.lp-left {
    align-items: flex-end;
  }
  &.description {
    width: 100%;
    & + & {
      margin: rem(48) 0 0;
    }
  }
}
.crs-component {
  position: relative;
  input {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    opacity: 0;
  }
}
.crs-label {
  overflow: hidden;
  transition: 400ms opacity;
  .lp-bottom &, .lp-top & {
    opacity: 0.5;
  }
  label {
    display: block;
    cursor: pointer;
  }
}
.crs-item {
  display: flex;
}
.switcher-element {
  --size: #{rem(23)};
  display: flex;
  border-radius: var(--size);
  background: sc(b, 0.2);
  min-width: calc(var(--size) * 2);
  height: var(--size);
  justify-content: center;
  align-items: center;
  font: 500 rem(8) / rem(12) $f;
  color: c(b);
  position: relative;
  transition: all 150ms;
  padding-inline-start: var(--size);
  padding-inline-end: rem(3);
  cursor: pointer;
  small {
    position: absolute;
    background: sc(primary);
    width: calc(var(--size) - #{rem(4)});
    height: calc(var(--size) - #{rem(4)});
    border-radius: #{rem(14)};
    top: rem(2);
    transition: all 150ms;
    left: rem(2);
  }
  @media (hover: hover) {
    .crs-label:hover ~ .crs-component &, .crs-component &:hover, label:hover & {
      small {
        width: var(--size);
      }
    }
  }
  &.active {
    background: var(--switcher-background, #{c(primary)});
    color: sc(primary);
    padding-inline-start: rem(3);
    padding-inline-end: var(--size);
    small {
      background: sc(primary);
      left: calc(100% - #{rem(2)});
      transform: translateX(-100%);
    }
  }
  &.s-big {
    --size: #{rem(24)};
  }
  &.read-only {
    background: none;
    border-color: sc(b, 0.38);
    color: sc(b, 0.38);
    small {
      background: sc(b, 0.38);
    }
    &.active {
      color: c(primary);
      small {
        background: c(primary);
      }
    }
  }
}
