@import "../../../assets/scss/magic";
@mixin gradient {
    background-image: linear-gradient(135deg, var(--gradeint-start) 0%, var(--gradeint-end) 100%);
}
.event-card-holder {
    --gradeint-start: #fba523;
    --gradeint-end: #ef6343;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    position: relative;
    border-radius: rem(14) rem(14) rem(10) rem(10);
    @include gradient;
    & + & {
        .aside-content & {
            margin: rem(16) 0 0;
        }
    }
    &.blocked {
        --gradeint-start: var(--danger);
        --gradeint-end: var(--hero);
        @include over(jaffa, danger);
    }
}
.ec-teams {
    width: 100%;
    color: sc(brand);
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    font: 500 rem(13) / rem(15) $f;
    padding: 0 rem(6);
    margin: rem(14) 0;
    > li {
        overflow: hidden;
        padding: 0 rem(6);
        &:first-child {
            text-align: end;
        }
        &:not(:nth-child(2)) {
            user-select: text;
            ::selection {
                background: sc(jaffa, 0.3);
                color: sc(jaffa);
            }
        }
    }
    .vs-holder {
        width: rem(24);
        height: rem(24);
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font: 700 rem(8) / rem(12) $f;
        background: sc(brand, 0.1);
        color: sc(brand, 0.8);
    }
}
.ec-details-holder {
    width: 100%;
    padding: 0 rem(1) rem(1);
    font: rem(12) / rem(14) $f;
}
.ec-details {
    background: c(b);
    color: sc(b);
    padding: rem(8);
    position: relative;
    display: flex;
    align-items: center;
    border-radius: rem(12) rem(12) rem(9) rem(9);
    transition: 400ms border-radius;
    &.no-border-radius-bottom {
        border-radius: rem(12) rem(12) 0 0;
    }
    > li {
        overflow: hidden;
        flex-shrink: 0;
        &:first-child {
            flex: auto;
        }
        &:nth-child(2) {
            padding: rem(8);
        }
    }
}
.event-card-button-holder {
    position: absolute;
    top: -rem(6);
    right: -rem(6);
    background: fixed 50% 0 / 50vw 100vh;
    border-radius: 100%;
    background: c(b);
    transition: 400ms transform;
    transform-origin: center center;
    .event-card-holder:not(:hover) & {
        transform: scale(0);
    }
}
.event-video-preview-holder {
    width: 100%;
    padding: per(185, 330) 0 0;
    position: relative;
    margin: rem(1) 0 0;
    background: black;
    border-radius: 0 0 rem(9) rem(9);
    overflow: hidden;
    .cart-item-holder & {
        margin: rem(20) 0 0;
    }
    .video-quick-preview-holder &, .cart-item-holder & {
        border-radius: rem(6);
    }
}
.event-video-loader-holder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 0 rem(40);
    p {
        margin: rem(12) 0 0;
        color: white;
        font: rem(12) / rem(20) $f;
    }
}
.event-video-preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: inherit;
    display: block;
}
.event-video-preview-close {
    position: absolute;
    top: rem(12);
    right: rem(12);
    z-index: 2;
    background: rgba(white, 0.1);
    color: white;
    border-radius: 100%;
    transition: 400ms background;
    &:hover {
        background: white;
        color: #08104d;
    }
}
.ec-tags-wrapped-holder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0 0 0 rem(10);
}
.stream-inline-actions-holder {
    width: 100%;
    padding: rem(6);
    display: grid;
    grid-column-gap: rem(1);
    grid-auto-flow: column;
    > * {
        border-radius: 0;
        &:first-child {
            border-top-left-radius: rem(36);
            border-bottom-left-radius: rem(36);
        }
        &:last-child {
            border-top-right-radius: rem(36);
            border-bottom-right-radius: rem(36);
        }
    }
}
