@import "../../../assets/scss/magic";
.tree-search-holder {
  width: 100%;
  position: sticky;
  top: 0;
  background: c(b);
  z-index: 5;
  padding: rem(18) rem(36) rem(10);
}
.tree-branches-holder {
  padding: 0 rem(36) rem(36);
}
.tree-no-result-holder {
  width: 100%;
  height: calc(100% - #{rem(70)});
  display: flex;
}
