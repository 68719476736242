@import "../../../assets/scss/magic";
.partners-list-container {
    width: 100%;
    background: c(b);
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    display: flex;
    flex-direction: column;
    .account-info-holder & {
        max-height: rem(316);
        background: sc(b, 0.05);
    }
}
.partners-list-search {
    padding: 0 rem(12) rem(12);
    width: 100%;
    flex-shrink: 0;
}
.partners-list-holder {
    padding: 0 rem(12) rem(12);
    background: sc(b, 0.05);
    width: 100%;
    max-height: rem(400);
    overflow-y: auto;
    border-radius: 0 0 rem(6) rem(6);
    color: sc(b, 0.5);
    flex: auto;
    > p {
        font: 700 rem(10) / rem(14) $f;
        padding: rem(18) rem(16) rem(10);
        text-transform: uppercase;
        letter-spacing: rem(1);
        color: sc(b, 0.4);
        text-align: center;
        .account-popover & {
            position: sticky;
            top: 0;
            z-index: 10;
            text-align: start;
            padding: rem(10) rem(16);
            background: c(b);
            box-shadow: inset 0 0 0 rem(100) sc(b, 0.05);
        }
    }
    .account-info-holder & {
        padding: 0 rem(24) rem(24);
        border-radius: rem(10);
    }
}
.partners-list {
    font: 500 rem(11) / rem(13) $f;
}
.project-row {
    display: flex;
    align-items: center;
    height: rem(42);
    padding: 0 0 0 rem(8);
    background: c(b);
    cursor: pointer;
    transition: 200ms background, 200ms color;
    text-transform: none;
    content-visibility: auto;
    &:first-child {
        border-top-left-radius: rem(8);
        border-top-right-radius: rem(8);
    }
    &:last-child {
        border-bottom-left-radius: rem(8);
        border-bottom-right-radius: rem(8);
    }
    &:not(:last-child) {
        border-bottom: rem(1) solid c(primary, 0.1);
    }
    li {
        flex-shrink: 0;
        padding: 0 rem(7);
        &:first-child {
            flex: auto;
        }
    }
    small {
        display: flex;
        justify-content: center;
        align-items: center;
        height: rem(28);
        font: 700 rem(8) / rem(10) $f;
        padding: 0 rem(6);
        border-radius: rem(4);
        letter-spacing: rem(1);
        text-transform: uppercase;
        transition: 200ms color, 200ms background;
        min-width: rem(56);
        text-align: center;
        background: c(primary, 0.1);
        color: c(primary);
    }
    &.active, &:hover {
        color: c(primary);
        small {
            background: c(primary);
            color: sc(primary);
        }
    }
    &.active {
        pointer-events: none;
        font-weight: 700;
    }
    &.t-live {
        @include over(primary, confirm);
    }
    &.t-pending {
        @include over(primary, jaffa);
    }
}
