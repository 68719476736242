@import '../../../assets/scss/magic';
.st-sub-table-holder {
    width: 100%;
    padding: rem(20);
}
.st-sub-table {
    border-radius: rem(8);
    background: c(b);
    color: sc(b);
    width: 100%;
    padding: rem(12) rem(24);
    border: rem(1) dashed c(accent);
    overflow: hidden;
    > ul {
        width: 100%;
        display: grid;
        grid-template-columns: auto 1fr auto;
        grid-column-gap: rem(20);
        align-items: center;
        height: var(--row-height);
        transition: 300ms color;
        > li {
            overflow: hidden;
            &:last-child {
                text-align: end;
                color: c(accent-hover);
                font-weight: 700;
            }
        }
        &.clickable {
            cursor: pointer;
            &:hover {
                color: c(accent-hover);
                .nested-table-divider {
                    border-color: c(accent-hover);
                }
            }
        }
    }
}
.nested-table-divider {
    border-top: rem(1) dashed sc(b, 0.2);
    transition: 300ms border-color;
}
