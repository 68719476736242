@import '../../../assets/scss/magic';
.bread-crumbs-holder {
    width: 100%;
    display: flex;
    align-items: center;
    font: 500 rem(13) / rem(15) $f;
    padding: 0 rem(5);
    > li {
        display: flex;
        align-items: center;
        padding: 0 rem(5);
        color: sc(b, 0.7);
        > .icon {
            opacity: 0.5;
        }
        &:last-child {
            color: c(primary);
        }
    }
    .bc-item {
        display: flex;
        align-items: center;
        transition: 400ms color;
        &:not(.cursor-pointer) {
            background: c(primary, 0.1);
            color: c(primary);
            padding: rem(8) rem(16);
            border-radius: rem(36);
            font-size: rem(12);
        }
        .icon {
            margin: 0 0 0 rem(5);
            transition: 400ms transform;
        }
        &.popover-opened {
            .icon {
                transform: rotate(-180deg);
            }
        }
        &.cursor-pointer {
            &:hover, &.popover-opened {
                color: c(primary);
            }
        }
    }
}
.navigation-dropdown {
    margin: rem(10) 0;
    min-width: rem(180);
    font: rem(13) / rem(15) $f;
    > li {
        transition: 400ms color;
        height: rem(42);
        display: flex;
        align-items: center;
        padding: 0 rem(20);
        color: sc(b);
        cursor: pointer;
        &.active {
            font-weight: 500;
        }
        &:hover, &.active {
            color: c(primary);
        }
    }
}
