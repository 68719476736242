@import "../../../assets/scss/magic";
.detail-row-item {
    width: 100%;
    display: flex;
    align-items: center;
    height: rem(42);
    font: rem(12) / rem(14) $f;
    color: sc(b, 0.6);
    transition: 400ms color;
    &.capitalize {
        text-transform: capitalize;
    }
    > li {
        display: flex;
        align-items: center;
        transition: 400ms transform, 400ms opacity;
    }
    .no-shrink {
        flex-shrink: 0;
        justify-content: center;
        width: rem(42);
        height: rem(42);
        color: c(jaffa);
        .flag-holder {
            color: inherit;
        }
    }
    .f-auto {
        flex: auto;
        overflow: hidden;
        @at-root {
            .a-compact#{&} {
                flex: inherit;
            }
        }
    }
    span {
        color: sc(b);
        transition: 400ms color;
    }
    &.cursor-pointer {
        *:hover > &:not(:hover), .detail-row-actions-reveal:not(:hover) & {
            .dr-action {
                opacity: 0;
            }
        }
        &:hover {
            color: c(jaffa, 0.7);
            span {
                color: c(jaffa-darken);
            }
            .dr-action {
                transform: translateX(rem(6));
            }
        }
    }
    &.t-gradient, &.t-danger {
        @include over(jaffa, confirm);
        font: rem(13) / rem(15) $f;
        .icon {
            &:before {
                display: block;
                background: linear-gradient(135deg, c(limerick) 0%, #0f9960 100%);
                color: rgba(#05ce06, 0.1);
                -webkit-background-clip: text;
            }
        }
    }
    &.t-danger {
        @include over(jaffa, danger);
        .icon {
            &:before {
                background: none;
                color: c(danger);
            }
        }
    }
}
