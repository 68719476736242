@import "../../../assets/scss/magic";
.btn {
    --size: var(--button-custom-size, #{rem(36)});
    --font-size: var(--button-custom-font-size, #{rem(14)});
    --tooltip-offset: #{rem(8)};
    min-width: var(--size);
    min-height: var(--size);
    border-radius: var(--size);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font: 500 var(--font-size) / rem(20) $f;
    cursor: pointer;
    color: inherit;
    border: rem(1) solid transparent;
    transition: 400ms color, 400ms background, 400ms opacity;
    position: relative;
    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }
    &.id-start {
        > * {
            + * {
                margin-inline-start: rem(7);
            }
        }
    }
    &.id-end {
        flex-direction: row-reverse;
        > * {
            + * {
                margin-inline-end: rem(7);
            }
        }
    }
    &.cr-smooth {
        border-radius: rem(4);
    }
    &.s-small {
        --size: #{rem(26)};
        --font-size: #{rem(12)};
    }
    &.s-medium {
        --size: #{rem(34)};
        --font-size: #{rem(12)};
    }
    &.s-big {
        --size: #{rem(42)}
    }
    &:not(.c-icon):not(.c-one) {
        --button-padding: calc(var(--size) / 2);
        padding: 0 var(--button-external-padding, var(--button-padding));
        &.f-content-size {
            .icon {
                margin: 0 -rem(4);
            }
        }
        &.f-default {
            min-width: rem(110);
        }
        &.f-full-width {
            width: 100%;
        }
    }
    &:not(.active) {
        &.a-outline {
            border-color: c(hero);
            color: c(hero);
            &:hover, &.popover-opened {
                background: c(hero);
                color: sc(hero);
            }
            &:active {
                background: c(hero);
                color: sc(hero);
            }
        }
        &.a-minimal {
            &:not(.c-default) {
                color: c(hero);
            }
            &:hover, &.active, &.popover-opened {
                background: sc(b, 0.03);
            }
        }
        &.a-grayscale {
            background: sc(b, 0.01);
            &:hover, &.active, &.popover-opened {
                background: sc(b, 0.03);
            }
            &:not(.c-default) {
                color: c(hero);
            }
        }
        &.a-light {
            background: c(hero, 0.07);
            color: c(hero);
            &:hover, &.active, &.popover-opened {
                background: c(hero);
                color: sc(hero);
            }
        }
    }
    &.a-default, &.active {
        background: c(hero);
        color: sc(hero);
        &:hover, &.popover-opened {
            background: ch(hero);
        }
    }
    &.c-primary {
        @include over(hero, primary);
    }
    &.c-accent {
        @include over(hero, accent);
        --hero-hover: var(--accent-lighten);
    }
    &.c-brand {
        @include over(hero, brand);
    }
    &.c-danger {
        @include over(hero, danger);
    }
    &.c-confirm {
        @include over(hero, confirm);
    }
    &.c-warning {
        @include over(hero, warning);
    }
    &.c-peach {
        @include over(hero, peach);
    }
    &.c-jaffa {
        @include over(hero, jaffa);
    }
    &.c-contrast {
        @include over(hero, contrast);
    }
    &.animation {
        animation: waves 1s alternate infinite;
        @keyframes waves {
            to {
                box-shadow: 0 0 0 rem(5) c(hero, 0.2),
                0 0 0 rem(10) c(hero, 0.2),
                0 0 0 rem(15) c(hero, 0.2),
                0 0 0 rem(20) c(hero, 0);
            }
        }
    }
    &[data-tip] {
        &:before {
            content: attr(data-tip);
            position: absolute;
            display: flex;
            align-items: center;
            padding: rem(0) rem(20);
            height: rem(36);
            border-radius: rem(36);
            box-shadow: 0 rem(3) rem(10) -rem(10) black;
            font: 500 rem(12) / rem(20) $f;
            white-space: nowrap;
            background: c(b);
            color: sc(b);
            z-index: 1000;
            transition: 300ms margin, 300ms opacity, 300ms visibility;
        }
    }
    &[data-tip-place='top'], &[data-tip-place='bottom'] {
        &:before {
            left: 50%;
            transform: translate(-50%, 0);
        }
    }
    &[data-tip-place='left'], &[data-tip-place='right'] {
        &:before {
            top: 50%;
            transform: translate(0, -50%);
        }
    }
    &[data-tip-place='top'] {
        &:before {
            bottom: 100%;
            margin: 0 0 var(--tooltip-offset);
        }
    }
    &[data-tip-place='bottom'] {
        &:before {
            top: 100%;
            margin: var(--tooltip-offset) 0 0;
        }
    }
    &[data-tip-place='right'] {
        &:before {
            left: 100%;
            margin: 0 0 0 var(--tooltip-offset);
        }
    }
    &[data-tip-place='left'] {
        &:before {
            right: 100%;
            margin: 0 var(--tooltip-offset) 0 0;
        }
    }
    &:not(:hover), &.popover-opened {
        &:before {
            --tooltip-offset: 0;
            visibility: hidden;
            opacity: 0;
        }
    }
}
.badge-count {
    position: absolute;
    top: 0;
    right: 0;
    min-width: rem(16);
    font: 700 rem(8) / rem(16) $f;
    padding: 0 rem(6);
    background: c(hero);
    color: sc(hero);
    border-radius: rem(10);
    transform: translate(25%, -20%) scale(0);
    letter-spacing: rem(0.5);
    animation: badge-reveal 300ms forwards;
    @include over(hero, danger);
    @keyframes badge-reveal {
        50% {
            transform: translate(25%, -20%) scale(1.5);
        }
        100% {
            transform: translate(25%, -20%) scale(1);
        }
    }
}
