@import '../../../assets/scss/magic';
.card-holder {
    width: 100%;
    height: rem(160);
    border-radius: rem(10);
    font: rem(16) / 1.2 monospace, serif;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    color: c(b);
    background-image: linear-gradient(-145deg, #2d3436 0%, #000000 74%);
    @media (prefers-color-scheme: dark) {
        background-image: linear-gradient(-145deg, #fff 0%, #d1d1e0 74%);
    }
    &:not(:hover) {
        .cc-actions-holder:not(.popover-opened) {
            opacity: 0;
        }
    }
    &.cc-paypal {
        .cc-number {
            font-size: rem(13);
        }
    }
    &:not(.cc-add) {
        &:before {
            $gap: 6;
            content: '';
            position: absolute;
            display: block;
            top: -rem($gap);
            left: -rem($gap);
            right: -rem($gap);
            bottom: -rem($gap);
            border: rem(1) solid sc(b);
            border-radius: rem(16);
            transition: 400ms opacity;
            pointer-events: none;
        }
        &:not(.selected) {
            &:before {
                opacity: 0;
            }
        }
    }
    &.cc-add {
        background: c(b);
        color: sc(b);
        border: 1px solid #4caf50;
        cursor: pointer;
        transition: 400ms color;
        .icon {
            -webkit-text-stroke: #{rem(2)} c(b);
            transition: 400ms transform;
        }
        &:hover {
            color: #4caf50;
            .icon {
                transform: scale(1.1);
            }
        }
        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
}
.cc-default-holder {
    position: absolute;
    bottom: rem(12);
    left: rem(12);
    height: rem(22);
    font: 700 rem(8) / rem(12) $f;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: rem(1);
    padding: 0 rem(10);
    color: sc(b);
    border-radius: rem(12);
    background: c(b);
    .icon {
        font-size: #{rem(14)};
        margin: 0 rem(2) 0 0;
    }
    span {
        margin: rem(2) 0 0;
    }
}
.cc-actions-holder {
    position: absolute;
    transition: 400ms opacity;
    top: rem(12);
    right: rem(12);
    @include over(hero, b);
}
.cc-logo-holder {
    position: absolute;
    left: rem(12);
    top: rem(12);
    .icon {
        --font-size: #{rem(40)};
    }
}
.cc-number {
    margin: rem(48) 0 rem(6);
    padding: 0 rem(24);
}
.cc-expiration {
    margin: 0 0 0 rem(38);
    font-size: rem(12);
}
.add-new-card-holder {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 0 rem(20);
    font: 700 rem(11) / rem(13) $f;
    text-transform: uppercase;
    letter-spacing: rem(1.5);
    .icon {
        margin: 0 0 rem(4);
    }
}
.cc-logo-holder {
    width: rem(48);
    height: rem(36);
    background: white no-repeat 50% 50% / contain;
    border-radius: rem(5);
    &.cc-american-express {
        background-image: url('./media/amex.svg');
    }
    &.cc-diners-club {
        background-image: url('./media/diners-club.svg');
    }
    &.cc-discover {
        background-image: url('./media/discover.svg');
    }
    &.cc-jcb {
        background-image: url('media/jcb.svg');
    }
    &.cc-maestro {
        background-image: url('./media/maestro.svg');
    }
    &.cc-mastercard {
        background-image: url('./media/mastercard.svg');
    }
    &.cc-paypal {
        background-image: url('./media/paypal.svg');
    }
    &.cc-visa {
        background-image: url('./media/visa.svg');
    }
}
