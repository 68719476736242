@import '../../../../assets/scss/magic';
.hosted-field-holder {
    width: 100%;
    > label {
        padding: 0 rem(20);
        color: sc(b, 0.6);
        margin: 0 0 rem(6);
        font: 500 rem(12) / rem(14) $f;
    }
}
.hosted-field {
    height: rem(42);
    width: 100%;
    background: #{sc(b, 0.04)};
    transition: 300ms background, 300ms border-color, 300ms opacity;
    padding: 0 rem(20);
    border-radius: rem(42);
    &.braintree-hosted-fields-focused {
        background: sc(b, 0.05);
    }
}
