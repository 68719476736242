@import "../../../../assets/scss/magic";
.account-popover {
  width: rem(300);
}
.user-profile-info-holder {
  padding: rem(14) rem(16);
  display: flex;
  align-items: center;
  .icon {
    width: rem(48);
    height: rem(48);
    border-radius: 100%;
    background: c(accent, 0.15);
    margin: 0 rem(12) 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: c(accent);
  }
  > li {
    &:first-child {
      flex-shrink: 0;
    }
    &:last-child {
      flex: auto;
      overflow: hidden;
    }
  }
  h3 {
    font: 500 rem(18) / rem(20) $f;
    text-transform: capitalize;
  }
  p {
    font: rem(12) / rem(16) $f;
    margin: rem(4) 0 0;
    opacity: 0.5;
  }
}
.select-project-holder {
  padding: 0 rem(16);
  transition: 400ms padding, 400ms margin;
  &:not(.active) {
    margin: 0 0 rem(16);
  }
  &.disabled {
    pointer-events: none;
    opacity: 1;
  }
  ul {
    display: flex;
    height: rem(36);
    align-items: center;
    padding: 0 rem(16);
    font: 500 rem(12) / rem(14) $f;
    background: c(primary, 0.1);
    border-radius: rem(36);
    color: c(primary);
    transition: 400ms background, 400ms color, 400ms padding, 400ms border-radius;
    cursor: pointer;
    text-transform: capitalize;
    &:hover {
      background: c(primary);
      color: sc(primary);
    }
    li {
      flex-shrink: 0;
      transition: 400ms transform;
      &:first-child {
        flex: auto;
        overflow: hidden;
        padding: 0 rem(6) 0 0;
      }
    }
  }
  &.active {
    padding: 0;
    ul {
      padding: 0 rem(28);
      background: none;
      color: c(primary, 0.7);
      border-radius: 0;
      &:hover {
        color: c(primary);
      }
      li {
        &:last-child {
          transform: rotate(90deg);
        }
      }
    }
  }
}
