@import "../../../assets/scss/magic";
.checkbox-in-form {
    display: flex;
    align-items: center;
    padding: rem(20) rem(20) 0;
}
.checkbox-holder {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    line-height: rem(18);
    input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        pointer-events: none;
        &:checked ~ small {
            box-shadow: inset 0 0 0 rem(20) c(primary);
            border-color: c(primary);
        }
        &:not(:checked) ~ small {
            .icon {
                transform: scale(0);
            }
        }
    }
    small {
        border: rem(1) solid sc(b, 0.17);
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem(18);
        height: rem(18);
        border-radius: rem(4);
        transition: border-color 400ms, box-shadow 400ms;
        box-shadow: inset 0 0 0 c(primary);
        background: c(b);
        position: relative;
        &:before {
            content: "\e92d";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: sc(b, 0.43);
            font: rem(24) / 1 'icomoon';
            transition: 400ms transform;
            transform: scale(0);
        }
        i {
            &.icon {
                transition: 400ms transform;
                color: sc(primary);
            }
        }
    }
    .checkbox-label {
        margin: 0 0 0 rem(12);
        transition: 400ms opacity;
        opacity: 0.6;
    }
    &.reversed {
        flex-direction: row-reverse;
        text-align: end;
        .checkbox-label {
            margin: 0 rem(12) 0 0;
        }
    }
    &:hover, label:hover & {
        small {
            border-color: c(primary);
        }
        .checkbox-label {
            opacity: 1;
        }
    }
    &.read-only {
        pointer-events: none;
        small {
            border-color: transparent;
            &:before {
                transform: scale(1);
            }
            i {
                &.icon {
                    font-size: rem(24);
                }
            }
        }
        input {
            &:checked {
                ~ small {
                    box-shadow: inset 0 0 0 c(primary);
                    border-color: transparent;
                    &:before {
                        transform: scale(0);
                    }
                    i {
                        &.icon {
                            color: c(primary);
                        }
                    }
                }
            }
        }
    }
}
