@import "../../../../assets/scss/magic";
.tree-branch {
    width: 100%;
    display: flex;
    align-items: center;
    height: rem(46);
    color: sc(b, 0.7);
    transition: 400ms color;
    text-transform: capitalize;
    content-visibility: auto;
    &.highlighted {
        position: sticky;
        top: rem(70);
        z-index: 10;
        font-weight: 500;
        background: c(b);
    }
    .t-line {
        border-top: rem(1) dashed sc(b, 0.17);
        transition: 400ms border-color;
        margin: 0 rem(24);
    }
    &:hover, &:focus-within {
        color: c(primary-darken);
        .t-line {
            border-color: c(primary);
        }
    }
}
.tree-leaf {
    flex-shrink: 0;
    &.t-flag {
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem(46);
    }
    &.t-line {
        flex: auto;
    }
    &.t-changer {
        &.padding {
            padding: 0 rem(24) 0 0;
        }
    }
}
