@import "../../../assets/scss/magic";
.popover-menu {
    padding: rem(6) 0;
    min-width: rem(180);
    &:not(:first-child) {
        border-top: rem(1) solid sc(b, 0.08);
    }
}
.h-divider {
    border-top: rem(1) solid sc(b, 0.08);
    margin: rem(6) 0;
}
.popover-menu-item {
    display: flex;
    height: rem(48);
    align-items: center;
    padding: 0 rem(16);
    font: 500 rem(14) / rem(16) $f;
    cursor: pointer;
    transition: 400ms color, 400ms opacity;
    text-transform: capitalize;
    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
    &:hover {
        color: c(primary);
    }
    @each $name, $color in $c {
        &.c-hover-#{$name} {
            @include over(primary, #{$name});
        }
    }
    .icon {
        flex-shrink: 0;
        margin: 0 rem(12) 0 0;
    }
    span {
        flex: auto;
        &:only-child {
            padding: 0 rem(6);
        }
    }
}
