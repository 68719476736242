@import "../../../assets/scss/magic";
.modal-holder {
    position: fixed;
    top: var(--header-height);
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 var(--header-height) var(--header-height);
}
.modal {
    background: c(b);
    position: relative;
    width: rem(900);
    filter: drop-shadow(0 rem(3) rem(6) rgba(#000000, 0.1));
    border-radius: 0 0 rem(12) rem(12);
    margin: 0 auto;
    transform: translate3d(0, -100%, 0);
    animation-delay: 300ms;
    animation-duration: 300ms;
    .t-createStream &,
    .t-presetInfo &,
    .t-assignNewLeague &,
    .t-addRecipient & {
        width: rem(680);
    }
    .t-changeStreamer &,
    .t-addPaymentMethod &,
    .t-deposit &,
    .t-message & {
        width: rem(500);
    }
    .t-settings & {
        width: rem(980);
        background: c(b, 0.5);
        padding: rem(12);
        @media (prefers-color-scheme: dark) {
            background: c(contrast, 0.05);
        }
    }
}
.modal-message-holder {
    width: 100%;
    display: flex;
    align-items: center;
    > li {
        padding: 0 rem(10);
        &:first-child {
            flex-shrink: 0;
        }
        &:last-child {
            flex: auto;
        }
    }
    .icon {
        border-radius: 100%;
        width: rem(80);
        height: rem(80);
        background: c(primary, 0.07);
        color: c(primary);
        &.icon-warning {
            @include over(primary, accent);
        }
        &.icon-success {
            @include over(primary, confirm);
        }
        &.icon-trash, &.icon-error {
            @include over(primary, danger);
        }
    }
}
.modal-head {
    font: 500 rem(16) / rem(20) $f;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    padding: rem(24) rem(24) 0;
    text-transform: capitalize;
    > li {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 rem(6);
        &:last-child {
            justify-content: flex-end;
        }
    }
}
.modal-content {
    padding: rem(20) rem(40) rem(36);
}
.modal-footer {
    padding: rem(20) rem(40);
    border-top: rem(1) dashed sc(b, 0.08);
    display: flex;
    align-items: center;
    color: sc(b, 0.5);
    > li {
        display: flex;
        align-items: center;
        &:first-child {
            flex: auto;
        }
        &:nth-child(2) {
            flex-shrink: 0;
        }
        &:only-child {
            justify-content: center;
        }
        > * {
            + * {
                margin: 0 0 0 rem(12);
            }
        }
    }
}
.modal-section {
    width: 100%;
    --color: orange;
    &.t-sport {
        @include over(warning, primary);
        --color: var(--warning);
    }
    &.t-countries_list {
        @include over(warning, confirm);
        --color: var(--warning);
    }
    &.t-sports {
        @include over(warning, confirm);
        --color: var(--warning);
    }
    &.t-regions {
        @include over(warning, primary);
        --color: var(--warning);
    }
    &.t-filterLeagues {
        @include over(warning, danger);
        --color: var(--warning);
    }
    &.t-booking_type {
        @include over(warning, accent);
        --color: var(--warning);
    }
}
.ms-head {
    display: flex;
    align-items: center;
    font: 500 rem(14) / rem(16) $f;
    text-transform: capitalize;
    padding: 0 rem(20) rem(14);
    color: sc(b);
}
.ms-body {
    display: flex;
    flex-wrap: wrap;
    background: c(warning, 0.05);
    border-radius: rem(12);
    padding: rem(20) rem(24) rem(12);
}
.m-fit-block {
    width: calc(100% + #{rem(40 * 2)});
    margin: rem(24) -rem(40) 0;
    &:last-child {
        margin-bottom: -rem(36);
    }
}

.hide {
    display: none;
}
