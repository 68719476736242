@import "../../../assets/scss/magic";
.r-slides-holder {
  width: 100%;
}
.r-slides {
  margin: 0 0 rem(12);
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  border-radius: rem(48);
  overscroll-behavior: none;
  background: c(page-background);
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
  > ul {
    display: inline-flex;
    margin: rem(8);
    > li {
      flex-shrink: 0;
      border-radius: rem(48);
      box-shadow: 0 rem(4) rem(6) -rem(10) #000000;
      transition: 400ms box-shadow;
      &:first-child {
        margin: -rem(8);
        padding: rem(8);
        background: c(page-background);
        position: sticky;
        left: 0;
        z-index: 1;
      }
      + li {
        margin: 0 0 0 rem(8);
      }
      &:hover {
        box-shadow: 0 rem(4) rem(6) -rem(8) #000000;
      }
    }
  }
}
.r-item-holder {
  height: rem(36);
  cursor: pointer;
  display: flex;
  width: rem(200);
  background: c(b);
  border-radius: rem(36);
  transition: 400ms background, 400ms color, 400ms filter, 400ms opacity, 400ms border-color;
  font: 500 rem(12) / rem(14) $f;
  text-transform: capitalize;
  border: rem(1) dashed c(primary, 0);
  > li {
    display: flex;
    align-items: center;
    &:first-child {
      flex: auto;
      overflow: hidden;
    }
    &:last-child {
      flex-shrink: 0;
      padding: 0 rem(1) 0 rem(8);
      color: sc(b, 0.5);
    }
  }
  .ri-icon {
    width: rem(24);
    height: rem(24);
    margin: rem(6);
    flex-shrink: 0;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 400ms background, 400ms color;
    font-size: rem(14);
    --si-b-opacity: 0.07;
  }
  .ri-title {
    flex: auto;
  }
  button {
    background: none;
    &:not(.popover-opened) {
      opacity: 0;
    }
  }
  &.active {
    background: c(primary);
    color: sc(primary);
    .ri-icon {
      background: sc(primary, 0.05);
      color: sc(primary);
    }
    button {
      color: sc(primary);
    }
  }
  &:hover {
    button {
      opacity: 1;
    }
  }
  &.outdated {
    &:not(:hover) {
      opacity: 0.5;
    }
    &:not(.active) {
      filter: saturate(0);
    }
  }
  &.frozen {
    border-color: c(primary, 0.5);
    &:not(.active) {
      color: c(primary, 0.5);
      .ri-icon {
        background: c(primary, 0.05);
        color: c(primary, 0.5);
      }
      button {
        color: c(primary)
      }
    }
    &.active {
      border-color: ch(primary);
    }
  }
}
