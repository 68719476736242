@import "../../../assets/scss/magic";
.no-data-holder {
    width: 100%;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: rem(24) rem(24) rem(100);
    .tab-body & {
        padding: 0 0 25vh;
    }
    .watch-list-c-holder &,
    .tab-body & {
        height: 100%;
    }
    &:not(.transparent) {
        background: c(b);
        border-radius: rem(8);
    }
    img {
        width: rem(240);
    }
    button {
        margin: rem(24) 0 0;
        min-width: rem(200);
    }
    &.s-extra-small {
        padding: rem(48) rem(12);
        img {
            width: auto;
            height: rem(108);
        }
        .no-data-text {
            font: rem(16) / rem(24) $f;
        }
    }
    &.s-small {
        padding: rem(12) rem(12) rem(48);
        img {
            width: rem(100);
        }
    }
    &.s-medium {
        padding: rem(60) 0 rem(90);
        img {
            width: rem(140);
            height: rem(126);
        }
    }
    .icon {
        color: sc(b, 0.24);
        margin: 0 0 rem(12);
    }
}
.no-data-text {
    max-width: rem(600);
    font: 500 rem(18) / rem(24) $f;
    color: sc(b, 0.6);
    margin: rem(12) 0 0;
    > * {
        &:not(:only-child):first-child {
            margin: 0 0 rem(8);
        }
        &:not(:first-child) {
            font: rem(14) / rem(24) $f;
        }
    }
    .s-small > & {
        font: 500 rem(16) / rem(24) $f;
    }
}
