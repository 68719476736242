@import "../../../assets/scss/magic";
.apps-popover {
  width: rem(330);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: rem(30);
  grid-column-gap: rem(24);
  grid-row-gap: rem(24);
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    font: 500 rem(12) / rem(14) $f;
    padding: rem(5) 0;
    transition: 400ms color;
    color: sc(b, 0.6);
    .app-icon {
      width: rem(50);
      height: rem(50);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: rem(50);
      border-radius: rem(8);
      margin: 0 0 rem(6);
      transition: 400ms transform;
      overflow: hidden;
      &.icon-betconstruct {
        color: var(--betconstruct-logo-color);
        background: var(--betconstruct-logo-background-color, transparent);
      }
      &.icon-backoffice {
        color: var(--backoffice-logo-color);
        background: var(--backoffice-logo-background-color, transparent);
      }
      &.icon-springbme {
        color: var(--springbme-logo-color);
        background: var(--springbme-logo-background-color, transparent);
      }
      &.icon-ott {
        color: var(--ott-logo-color);
        background: var(--ott-logo-background-color, transparent);
      }
      &.icon-scoutdata, &.icon-scouting {
        color: var(--scoutdata-logo-color);
        background: var(--scoutdata-logo-background-color, transparent);
      }
    }
    &:hover {
      color: sc(b);
      .app-icon {
        transform: translateY(-rem(4));
      }
    }
  }
}
