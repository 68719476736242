@import "../../../assets/scss/magic";
.time-picker-holder {
  display: flex;
  width: 100%;
  > li {
    overflow-y: auto;
    overflow-x: hidden;
    width: 50%;
    padding: rem(6) 0;
    max-height: rem((46 * 5) + 12);
    + li {
      border-left: rem(1) solid sc(b, 0.08);
    }
  }
}
.time-picker-item {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: rem(46);
  cursor: pointer;
  transition: 400ms background, 400ms color;
  &:hover {
    background: sc(b, 0.05);
  }
  &.active {
    color: c(primary);
    pointer-events: none;
    font-weight: 500;
  }
}
