@import "../../../assets/scss/magic";
.simple-table-container {
    width: 100%;
    &.splash {
        height: 100%;
        display: grid;
        grid-template-rows: auto 1fr auto;
    }
}
.table-actions {
    width: 100%;
    display: flex;
    align-items: center;
    padding: rem(30);
    > li {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        &:first-child {
            flex: auto;
            > * {
                + * {
                    margin: 0 0 0 rem(24);
                }
            }
        }
        .ct-search {
            width: rem(260);
            .simple-table-full-width-search & {
                width: 100%;
            }
        }
        .ct-actions-holder {
            min-width: rem(600);
        }
    }
}
.table-header {
    width: 100%;
    padding: rem(30);
    background: c(b);
    &:empty {
        display: none;
    }
    .table-actions + & {
        padding-top: 0;
    }
}
.s-table-holder {
    width: 100%;
    background: c(b);
    overflow-x: auto;
    overflow-y: auto;
    .modal & {
        &::-webkit-scrollbar {
            display: none;
        }
    }
}
.table {
    width: 100%;
    --row-height: #{rem(46)};
    font: rem(13) / var(--row-height) $f;
    color: sc(b, 0.75);
    transform: translate3d(0, 0, 0);
    .empty & {
        min-height: 100%;
    }
    .table-actions + & {
        margin: 0;
    }
    ::first-letter {
        text-transform: uppercase;
    }
    tr {
        content-visibility: auto;
    }
    th, td {
        min-height: rem(44);
        vertical-align: middle;
        position: relative;
        transition: 200ms color, 200ms background, 200ms border, 200ms box-shadow;
        background: c(b);
        &.t-amount, &.t-total, &.t-total_price {
            text-align: end;
            justify-content: flex-end;
        }
        > .ellipsis-text {
            max-width: rem(12 * 280 / 10);
        }
    }
    th {
        position: sticky;
        top: 0;
        z-index: 3;
        &:not(.table-action-holder) {
            + th:not(.table-action-holder) {
                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: calc(50% - (var(--row-height) / 4));
                    left: 0;
                    width: rem(1);
                    height: calc(var(--row-height) / 2);
                    background: sc(b, 0.1);
                }
            }
        }
    }
    tbody {
        tr {
            &:not(:last-child) td {
                border-bottom: rem(1) solid sc(b, 0.07);
            }
            &.odd {
                td {
                    background: c(b);
                    box-shadow: inset 0 0 0 var(--row-height) sc(b, 0.03);
                }
            }
            &.clickable:hover, &.active {
                td {
                    background: c(primary, 0.09);
                    border-color: c(primary, 0.05);
                    color: c(primary);
                    &.table-action-holder {
                        background: c(b);
                        border-color: c(primary, 0.14);
                        box-shadow: inset 0 0 0 var(--row-height) c(primary, 0.09);
                    }
                }
                &.odd {
                    td {
                        &.table-action-holder {
                            box-shadow: inset 0 0 0 var(--row-height) sc(b, 0.03), inset 0 0 0 var(--row-height) c(primary, 0.09);
                        }
                    }
                }
            }
            &.active {
                td {
                    border-bottom-style: dashed;
                }
                &:hover {
                    td {
                        color: c(primary-hover);
                    }
                }
            }
            &.clickable {
                td {
                    cursor: pointer;
                }
            }
        }
    }
}
.table-action-holder {
    min-width: var(--row-height);
    width: var(--row-height);
    position: relative;
    tbody &:last-child {
        position: sticky;
        right: 0;
        z-index: 2;
    }
    .icon {
        transition: 300ms transform;
        &.active {
            transform: rotate(180deg);
        }
    }
}
.table-cell, .table-head {
    padding: 0 rem(20);
    justify-content: inherit;
    .table-action-holder + * & {
        padding-left: 0;
    }
}
.table-head {
    width: 100%;
    display: flex;
    align-items: center;
    color: sc(b);
    font-weight: 500;
    height: rem(46);
    justify-content: inherit;
    border-bottom: rem(1) solid sc(b, 0.07);
    background: sc(b, 0.05);
    transition: 400ms background, 400ms color;
    &.active {
        color: c(primary);
    }
    &.clickable {
        cursor: pointer;
        &:hover {
            background: sc(b, 0.08);
        }
    }
    .sorting-icons-st {
        position: relative;
        transition: 400ms opacity;
        margin: 0 0 0 rem(6);
        .icon {
            transition: 400ms color;
            color: sc(b, 0.5);
            &:nth-child(2) {
                position: absolute;
                top: 0;
                left: 0;
            }
            &.active {
                color: c(primary);
            }
        }
    }
}
.row-actions {
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 rem(12);
}
.st-c-holder {
    width: 100%;
    display: flex;
    align-items: center;
    height: var(--row-height);
    &.justify-end {
        justify-content: flex-end;
    }
}
.sp-skeleton {
    height: var(--row-height);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: inherit;
    &:before {
        content: "";
        display: block;
        height: rem(5);
        border-radius: rem(5);
        width: 80%;
        background: linear-gradient(135deg, sc(b, 0.07) 0%, sc(b, 0.05) 50%, sc(b, 0.07) 100%) 0 0 / 200%;
        animation: sp-skeleton 0.3s infinite linear;
        tr:nth-child(odd) td:nth-child(even) & {
            width: 60%;
        }
        tr:nth-child(even) td:nth-child(odd) & {
            width: 60%;
        }
    }
    &.circle {
        &:before {
            width: rem(24) !important;
            height: rem(24) !important;
            border-radius: 100%;
            margin: auto;
        }
    }
}
.empty-placeholder {
    width: rem(36);
    border-top: rem(1) dashed sc(b, 0.25);
}
.fake-scrollbar {
    width: 100%;
    height: map-get($scrollbar, 'size');
    position: sticky;
    bottom: 0;
    margin: calc(#{map-get($scrollbar, 'size')} * -1) 0 0;
    display: none;
    background: c(b);
    z-index: 10;
    cursor: pointer;
    transition: 300ms opacity;
    @supports (-webkit-background-clip: text) {
        background: transparent;
    }
    .modal & {
        display: block;
    }
    .s-table-holder:not(:hover) ~ &:not(:hover):not(:focus-within):not(:active) {
        opacity: 0;
    }
    .pagination-holder ~ & {
        bottom: rem(76);
    }
    .fake-scrollbar-thumb {
        width: 300px;
        display: block;
        height: 100%;
        border: map-get($scrollbar, 'space') solid transparent;
        border-radius: map-get($scrollbar, 'size');
        background: map-get($scrollbar, 'thumbBackground') content-box;
        transition: 200ms background;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        &:hover, &:active {
            background-color: map-get($scrollbar, 'thumbActiveBackground');
        }
    }
}
.api-key-input {
    display: flex;
}
