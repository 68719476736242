@import "../../../../assets/scss/magic";
.dropdown-item {
    width: 100%;
    padding: 0 rem(20);
    cursor: pointer;
    display: flex;
    font: rem(14) / rem(18) $f;
    &.virtualized {
        position: absolute;
        left: 0;
    }
    &.sticky {
        background: c(b);
        position: sticky;
        top: 0;
        z-index: 1;
    }
    > ul {
        width: 100%;
        height: rem(46);
        display: grid;
        grid-template-columns: 1fr rem(18);
        grid-column-gap: rem(12);
        transition: 400ms color;
        > li {
            display: flex;
            align-items: center;
            &:first-child {
                overflow: hidden;
            }
            &:last-child {
                justify-content: center;
            }
        }
        .icon {
            transform: scale(0);
            transition: 400ms transform, 400ms color;
            color: c(primary);
            &.icon-plus {
                color: c(confirm);
            }
            &.icon-trash {
                color: sc(b, 0.3);
                &:hover {
                    color: c(danger);
                }
            }
        }
    }
    &.active:not(.multi-select):not(.deselect):not(.with-action) {
        pointer-events: none;
    }
    &:hover, &.active:not(.multi-select) {
        color: c(primary);
    }
    &:hover .icon, &.active .icon:not(.icon-trash) {
        transform: scale(1);
    }
    &.suggestion {
        &:hover, &.active {
            color: c(confirm);
        }
    }
}
.option-holder {
    display: flex;
    width: 100%;
    align-items: center;
    > li {
        &.option-icon {
            width: rem(20);
            height: rem(20);
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 rem(10) 0 0;
            flex-shrink: 0;
        }
        &.option-text {
            flex: auto;
            overflow: hidden;
            &.flexible {
                display: flex;
                align-items: center;
                > * {
                    flex-shrink: 0;
                    &:first-child {
                        flex: auto;
                    }
                    &:not(:first-child):last-child {
                        margin: 0 rem(12) 0 0;
                    }
                }
            }
        }
    }
    strong {
        font-weight: 500;
        padding: 0 0 0 rem(10);
    }
}
.dropdown-skeleton-line {
    height: rem(6);
    border-radius: rem(6);
    width: 80%;
    background: sc(b, 0.1);
    .dropdown-item:nth-child(even) & {
        width: 100%;
    }
}
.option-description {
    width: 100%;
    font: 500 rem(10) / rem(12) $f;
    margin: rem(2) 0 0;
    opacity: 0.5;
}
.d-option {
    width: 100%;
    overflow: hidden;
    display: flex;
    &.d-option-vertical {
        flex-direction: column;
        align-items: flex-start;
    }
    &.d-option-spaced {
        > li {
            flex-shrink: 0;
            &:first-child {
                flex: auto;
            }
            &:nth-child(2) {
                padding: 0 0 0 rem(10);
            }
        }
    }
}
