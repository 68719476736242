@import '../../../assets/scss/magic';
.title-component-holder {
    display: grid;
    width: 100%;
    grid-template-columns: auto 1fr;
    grid-column-gap: rem(20);
    font: 700 rem(10) / rem(20) $f;
    letter-spacing: rem(1);
    color: c(primary);
    text-transform: uppercase;
    &:not(:first-child) {
        margin: rem(24) 0 0;
    }
    > li {
        display: flex;
        align-items: center;
        > hr {
            border-top: rem(1) dashed c(primary, 0.5);
            width: 100%;
        }
    }
}
