@import "core";

$baseBackground: #f1f4fa;
$baseColor: #08104d;
$scaleColor: #676a80;

$c: (
    hero: #e20f51,
    primary: #156bff,
    accent: #ffbd01,
    brand: #e20f51,
    danger: #e64e48,
    confirm: #1dbb2d,
    warning: #e6af57,
    peach: #ec5047,
    jaffa: #f58139,
    limerick: #8fbd24,
    buckthron: #fba424,
    b: #ffffff,
    contrast: #ffffff,
    f: #293042,
    d: saturate(darken(mix(#242a39, #171c26), 2.5), 10),
    c: #08104d,
);

@each $name, $color in $c {
    $c: map-merge($c, (#{$name + '-hover'}: set-lightness($color, 5)));
    $c: map-merge($c, (#{$name + '-lighten'}: lighten($color, 10)));
    $c: map-merge($c, (#{$name + '-darken'}: darken($color, 10)));
    $c: map-merge($c, (#{$name + '-sc'}: set-color($color)));
    $c: map-merge($c, (#{$name + '-rgb'}: #{red($color), green($color), blue($color)}));
    $c: map-merge($c, (#{$name + '-sc-rgb'}: #{red(set-color($color)), green(set-color($color)), blue(set-color($color))}));
}

$f: var(--font-family);

$globalGap: 14;

$scrollbar: (
    'size': var(--scrollbar-size, #{rem(16)}),
    'thumbSize': var(--scrollbar-thumbSize, #{rem(8)}),
    'gutter': var(--scrollbar-gutter, #{rem(10)}),
    'space': var(--scrollbar-space, #{rem(5)}),
    'thumbBackground': sc(b, 0.08),
    'thumbActiveBackground': sc(b, 0.12),
);
