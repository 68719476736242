@import '../../../assets/scss/magic';
.module-header {
    display: flex;
    width: 100%;
    background: c(b);
    height: rem(56);
    padding: 0 calc(#{rem(10)} + var(--module-title-aditional-padding, 0px)) 0 calc(#{rem(20)} + var(--module-title-aditional-padding, 0px));
    flex-shrink: 0;
    ::first-letter {
        text-transform: uppercase;
    }
    &.p-top {
        box-shadow: inset 0 -rem(1) 0 0 sc(b, 0.07);
        &.cr-position-radius {
            border-radius: rem(8) rem(8) 0 0;
        }
    }
    &.p-bottom {
        box-shadow: inset 0 rem(1) 0 0 sc(b, 0.07);
        &.cr-position-radius {
            border-radius: 0 0 rem(8) rem(8);
        }
    }
    &.cr-full-radius {
        border-radius: rem(20);
    }
    > li {
        display: flex;
        align-items: center;
        &.left-content {
            flex: auto;
            position: relative;
            overflow: hidden;
            .left-inner {
                max-width: 100%;
                user-select: text;
                &:nth-last-child(2) {
                    max-width: calc(100% - #{rem(32)});
                }
            }
            .left-inner-icon {
                margin-inline-start: rem(8);
            }
        }
        &.right-content {
            flex-shrink: 0;
            > * {
                + * {
                    margin: 0 0 0 rem(8);
                }
            }
        }
    }
    &.s-small {
        height: rem(30);
        .module-title, .module-description-title .m-title {
            font-size: rem(12);
            line-height: normal;
        }
    }
    &.s-medium {
        height: rem(40);
    }
    &.s-extra-big {
        height: rem(60);
    }
}
.module-title {
    font: 500 rem(14) / rem(16) $f;
}
.module-title-decoration {
    position: absolute;
    height: rem(3);
    width: rem(34);
    background: c(primary);
    left: 0;
    .p-top & {
        bottom: 0;
        border-radius: rem(3) rem(3) 0 0;
    }
    .p-bottom & {
        top: 0;
        border-radius: 0 0 rem(3) rem(3);
    }
}
.module-description-title {
    width: 100%;
    .m-description {
        font: 700 rem(10) / rem(16) $f;
        opacity: 0.5;
    }
    .m-title {
        font: 500 rem(14) / rem(22) $f;
    }
}
