@import "../../../assets/scss/magic";
.input-with-accept-holder {
  display: flex;
  align-items: center;
  padding: 0 rem(12) 0 0;
  &.readonly {
    pointer-events: none;
  }
}
.input-with-accept-input-holder {
  width: rem(110);
  flex-shrink: 0;
  margin: 0 rem(12) 0 0;
  input {
    text-align: center;
  }
}
.input-with-accept-button-holder {
  flex-shrink: 0;
  max-width: 0;
  height: rem(32);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: 150ms max-width;
  &.active {
    max-width: rem(32);
  }
}
