@import "../../../assets/scss/magic";
.popover-destination {
  position: relative;
  &.popover-opened {
    z-index: 99;
  }
}
.popover-holder {
  filter: drop-shadow(0 rem(3) rem(6) rgba(black, 0.1));
  z-index: 99;
  &.p-sticky {
    z-index: 100;
  }
}
.popover-c {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background: c(b);
  border-radius: rem(6);
  animation: 300ms popover-reveal forwards;
  @keyframes popover-reveal {
    0% {
      clip-path: inset(0 0 100% 0);
      opacity: 0;
    }
    100% {
      clip-path: inset(0);
      opacity: 1;
    }
  }
  > li {
    flex-shrink: 0;
    &.po-content {
      flex: auto;
      overflow-x: hidden;
      overflow-y: auto;
      border-radius: rem(6);
      min-height: rem(1);
      overscroll-behavior: none none;
      .a-inline & {
        min-width: rem(290);
      }
      .ReactVirtualized__Grid, .ReactVirtualized__Grid__innerScrollContainer {
        overflow: visible !important;
        width: 100% !important;
      }
      > div {
        &[style*="width"] {
          width: 100% !important;
        }
      }
    }
    &:not(.po-content) {
      background: c(b);
      position: sticky;
      z-index: 10;
    }
    &.po-header {
      top: 0;
      border-radius: rem(6) rem(6) 0 0;
      padding: rem(20);
      border-bottom: rem(1) solid sc(b, 0.08);
    }
    &.po-footer {
      bottom: 0;
      border-radius: 0 0 rem(6) rem(6);
    }
  }
  .divider {
    height: rem(1);
    background: sc(b, 0.08);
  }
}
