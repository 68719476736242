@import "../../../assets/scss/magic";
.weekday-picker-holder {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: rem(16) 0;
}
.weekday-picker {
  display: flex;
  width: 100%;
  cursor: pointer;
  margin: rem(4) 0 0;
  transition: 400ms opacity;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  label {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font: 500 rem(11) / rem(12) $f;
    cursor: pointer;
    > span {
      margin: rem(8) 0 0;
    }
  }
  .divider {
    flex-shrink: 0;
    margin: 0 rem(6);
    height: rem(38);
    border-left: rem(1) dashed sc(b, 0.15);
  }
}
