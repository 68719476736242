@import "../../assets/scss/magic";
.wallet-holder {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: rem(24);
    grid-column-gap: rem(24);
    > li {
        display: flex;
        flex-direction: column;
        > h3 {
            padding: 0 rem(24);
            color: sc(b, 0.6);
            margin: 0 0 rem(12);
            font: 500 rem(12) / rem(14) $f;
        }
    }
}
.wallet-balance-holder {
    width: calc(100% + (var(--tab-content-padding) * 2));
    background-image: linear-gradient(to right, #50cc7f 0%, #9bce5d 100%);
    color: white;
    padding: rem(32) var(--tab-content-padding);
    margin: 0 calc(var(--tab-content-padding) * -1);
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    grid-column-gap: rem(24);
    min-width: rem(320);
    --contrast-sc: #0e5d08;
    @include over(b, contrast);
    h3 {
        font: 700 rem(48) / 1 $f;
    }
    > li {
        display: flex;
        &.hr {
            background-image: linear-gradient(to right, white 33%, rgba(255,255,255,0) 0%);
            background-position: bottom;
            background-size: rem(8) rem(1);
            background-repeat: repeat-x;
            height: rem(1);
        }
    }
}
.saved-payments-holder {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: rem(24);
    grid-column-gap: rem(24);
}
.deposit-toggle-holder {
    position: relative;
    display: flex;
    align-items: center;
    > li {
        overflow: hidden;
        flex-shrink: 0;
    }
    &:not(.active) > li:last-child, &.active > li:first-child {
        max-width: 0;
    }
}
.deposit-input-holder {
    display: flex;
    align-items: center;
    width: rem(280);
    background: c(b);
    color: sc(b);
    border-radius: rem(60);
    padding: rem(6);
    label {
        display: block;
    }
    > * {
        + * {
            margin: 0 0 0 rem(6);
        }
    }
}
.wallet-d-buttons {
    position: relative;
    > li {
        &:nth-child(2) {
            position: absolute;
            top: 0;
            right: 0;
            overflow: hidden;
            width: 100%;
            height: 100%;
            opacity: 0.001;
            border-radius: rem(42);
        }
    }
}
