@import "../../../assets/scss/magic";
.input-holder {
    position: relative;
    width: 100%;
    transition: 300ms opacity;
    user-select: none;
    max-width: 100%;
    &.disabled {
        opacity: 0.4;
        pointer-events: none;
    }
    // Sizes
    --input-message-padding-side: #{rem(20)};
    --input-element-padding-side: #{rem(5)};
    --input-element-height: #{rem(36)};
    --textarea-height: #{rem(215)};
    &.s-big {
        --input-element-height: #{rem(42)};
        --textarea-height: #{rem(520)};
    }
    &.s-small {
        --input-element-height: #{rem(32)};
        --textarea-height: #{rem(148)};
    }
    // Direction
    &.id-end {
        --direction: row-reverse;
    }
    // Corner Radius
    &.cr-smooth {
        --input-element-border-radius: #{rem(4)};
        --textarea-border-radius: #{rem(4)};
    }
    // Appearance
    --swap-label-color: inherit;
    &.a-outline {
        --input-element-border-color: #{sc(b, 0.14)};
        &:focus-within, &.popover-opened, &:hover, .external-hover:hover & {
            --input-element-border-color: #{c(primary)};
            --swap-label-color: #{c(primary)};
        }
    }
    &.a-minimal {
        --input-element-background: #{sc(b, 0.04)};
        &:focus-within, &.popover-opened, &:hover, .external-hover:hover & {
            --input-element-background: #{sc(b, 0.05)};
        }
        &.s-small {
            --input-message-padding-side: #{rem(7)};
            --input-element-background: #{sc(b, 0.04)};
            --input-element-padding-side: #{rem(3)};
            &:focus-within, &.popover-opened, &:hover, .external-hover:hover & {
                --input-element-background: transparent;
                --input-element-border-color: #{c(primary)};
                --swap-label-color: #{c(primary)};
            }
        }
    }
    &.a-light {
        --input-font-size: #{rem(13)};
        --input-element-padding-side: #{rem(27)};
        &:focus-within, &.popover-opened, &:hover, .external-hover:hover & {
            --input-element-background: #{sc(b, 0.04)};
        }
    }
    &.a-inline {
        width: auto;
    }
    &.error-color {
        --swap-label-color: #{c(danger)} !important;
        --input-element-border-color: #{c(danger)} !important;
    }
    &.write-protected {
        pointer-events: none;
    }
}
$minimalSmall: '.input-holder.a-minimal.s-small';
.input-structure {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 var(--input-structure-padding, #{rem(15)});
    flex-direction: var(--direction, row);
    #{$minimalSmall} & {
        padding: 0 var(--input-structure-padding, #{rem(4)});
    }
    > {
        * {
            flex-shrink: 0;
            &:not(label) {
                position: relative;
                z-index: 1;
                opacity: 0;
                transform: scale(0.4);
                animation: input-actions-reveal 400ms forwards;
                overflow: hidden;
                max-width: 0;
                #{$minimalSmall} & {
                    margin: 0 rem(3);
                }
                @keyframes input-actions-reveal {
                    to {
                        max-width: rem(48);
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            }
        }
        label {
            flex: auto;
            display: block;
            overflow: hidden;
            cursor: inherit;
        }
    }
    .icon {
        transition: 300ms color, 300ms background;
        color: sc(b, 0.5);
        &.icon-arrow-down-outline {
            &:before {
                display: block;
                transition: 400ms transform;
                .popover-opened & {
                    transform: rotate(180deg);
                }
            }
        }
    }
}
.input-element-back {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: var(--input-element-background);
    border: rem(1) solid var(--input-element-border-color, transparent);
    border-radius: var(--input-element-border-radius, #{rem(42)});
    transition: 300ms background, 300ms border-color, 300ms opacity;
    cursor: text;
    .read-only & {
        cursor: inherit;
    }
}
.input-element {
    position: relative;
    z-index: 1;
    width: 100%;
    height: var(--input-element-height);
    padding: 0 var(--input-element-padding-side);
    font: var(--input-font-size, #{rem(14)}) $f;
    color: sc(b, 0.75);
    resize: none;
    @include placeholder {
        font-size: var(--input-font-size, #{rem(14)});
        color: sc(b, 0.4);
        transition: 150ms color;
    }
    &:focus {
        &:not([readonly]) {
            @include placeholder {
                color: transparent;
            }
        }
    }
    &.read-only {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &:placeholder-shown:not(:focus) {
        ~ .input-element-back {
            .input-swap-animate {
                transform: translateY(100%);
                opacity: 0;
            }
        }
    }
    @at-root {
        textarea#{&} {
            padding: rem(10) var(--input-element-padding-side);
            height: var(--textarea-height);
            border: rem(1) solid transparent;
            ~ .input-element-back {
                border-radius: var(--textarea-border-radius, #{rem(10)});
            }
        }
    }
    &[readonly] {
        cursor: inherit;
    }
}
.input-swap-label {
    position: absolute;
    top: -rem(7);
    left: 0;
    width: 100%;
    font: rem(10) / rem(14) $f;
    padding: 0 var(--input-message-padding-side);
    display: flex;
    pointer-events: none;
    text-transform: capitalize;
}
.input-swap {
    overflow: hidden;
    max-width: 100%;
}
.input-swap-animate {
    padding: 0 rem(4);
    color: var(--swap-label-color);
    background: c(b);
    box-shadow: inset 0 -rem(8) 0 var(--input-element-background);
    transition: transform 300ms, opacity 300ms 100ms, color 300ms;
    max-width: 100%;
}
.information-message {
    width: 100%;
    margin: rem(6) 0 0;
    padding: 0 var(--input-message-padding-side);
    font: 500 rem(12) / 1.42 $f;
    &.color-danger {
        color: c(danger);
    }
    &:empty {
        display: none;
    }
}
.input-title {
    max-width: 100%;
    padding: 0 var(--input-message-padding-side);
    color: sc(b, 0.6);
    margin: 0 0 rem(6);
    font: 500 rem(12) / rem(14) $f;
    text-transform: capitalize;
}
.inline-input-holder {
    max-width: 100%;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: sc(b, 0.8);
    transition: 400ms color;
    line-height: rem(18);
    text-transform: capitalize;
    .icon {
        flex-shrink: 0;
        margin: 0 0 0 rem(3);
        opacity: 0;
        transition: 400ms opacity, 400ms transform;
    }
    .inline-input-hover:hover &, &:hover, .popover-opened > & {
        .icon {
            opacity: 1;
        }
        &:hover {
            color: ch(primary);
        }
    }
    &:hover, .popover-opened > & {
        color: c(primary);
    }
    .popover-opened > & {
        .icon {
            transform: rotate(180deg);
        }
    }
}
.asterisk {
    color: c(danger);
    font-weight: 700;
}
