@import "../../../assets/scss/magic";
.not-found-holder {
  width: 100%;
  min-height: 100%;
  display: flex;
  padding: 0 0 rem(100);
}
.not-found {
  margin: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-content: center;
  align-items: center;
  img {
    width: rem(600);
    margin: 0 0 -rem(60);
  }
  h2 {
    font: 700 rem(54) / 1 $f;
    margin: 0 0 rem(24);
    color: white;
    text-transform: uppercase;
  }
  h3 {
    font: 700 rem(20) / 1 $f;
    margin: 0 0 rem(24);
    text-transform: capitalize;
  }
  .button-holder {
    width: rem(200);
  }
}
