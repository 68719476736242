@import "../../../assets/scss/magic";
.header {
    height: var(--header-height);
    padding: 0 rem($globalGap);
    background: c(b);
    color: sc(b, 0.8);
    display: flex;
    align-items: center;
    position: relative;
    z-index: 10;
    justify-content: center;
    @include over(b, f);
    .logo-holder {
        color: sc(b);
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: rem(30);
        .icon-logo {
            font-size: rem(16);
            display: flex;
            width: rem(110);
            justify-content: center;
        }
        .icon-friendship-logo-spaced {
            font-size: rem(8);
            margin: rem(6) 0 0;
            opacity: 0.8;
            transition: 250ms opacity, 250ms margin;
            pointer-events: none;
        }
        &:hover {
            .icon-by-feedconstruct {
                opacity: 0;
                margin: -rem(6) 0 0;
            }
        }
    }
    .mini-logo {
        width: rem(34);
        height: rem(34);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: rem(8);
        color: sc(b);
        font-size: rem(28);
        transition: 300ms box-shadow;
        position: relative;
        background: sc(b, 0.15);
        &.clickable {
            box-shadow: 0 0 0 sc(b, 0.15);
            &:hover {
                box-shadow: 0 0 0 rem(4) sc(b, 0.15);
            }
        }
        &:not(.clickable) {
            background: sc(b, 0.05);
            color: sc(b, 0.5);
        }
        .mini-logo-tooltip {
            position: absolute;
            left: 50%;
            top: 100%;
            transform: translate3d(-50%, 0, 0);
            white-space: nowrap;
            background: c(b);
            color: sc(b);
            display: flex;
            align-items: center;
            padding: rem(0) rem(12);
            height: rem(28);
            border-radius: rem(36);
            box-shadow: 0 rem(3) rem(10) -rem(10) black;
            font: 500 rem(10) / rem(16) $f;
            z-index: 1000;
            transition: 300ms margin, 300ms opacity, 300ms visibility;
            margin: rem(10) 0 0;
        }
        &:not(:hover) {
            .mini-logo-tooltip {
                margin: 0;
                opacity: 0;
                visibility: hidden;
            }
        }
    }
    .divider {
        width: rem(1);
        height: rem(24);
        background: sc(b, 0.1);
        &:last-child {
            display: none;
        }
    }
}
.header-content {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    max-width: 100%;
    > li {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        &:not(:nth-child(2)) {
            > * {
                + * {
                    margin: 0 0 0 rem(12);
                }
            }
        }
        &:last-child {
            justify-content: flex-end;
        }
    }
}
nav {
    display: flex;
    a {
        display: block;
        font: 500 rem(13) / rem(50) $f;
        padding: 0 rem(24);
        box-shadow: inset 0 0 0 c(hero);
        transition: 400ms box-shadow, 400ms color, 400ms background;
        &:hover {
            color: sc(b);
            background: sc(b, 0.05);
        }
        &.active {
            box-shadow: inset 0 -rem(50) 0 c(hero);
            color: sc(hero);
            &:hover {
                box-shadow: inset 0 -rem(50) 0 c(hero-hover);
            }
        }
    }
}
.user-profile-holder {
    display: flex;
    align-items: center;
    height: rem(36);
    border-radius: rem(36);
    background: sc(b, 0.05);
    transition: 400ms background, 400ms color;
    cursor: pointer;
    padding: 0 rem(12) 0 0;
    .icon-user-outline {
        width: rem(28);
        height: rem(28);
        margin: rem(4);
        border-radius: 100%;
        background: sc(b, 0.8);
        color: c(b, 0.8);
        transition: 400ms background, 400ms color;
    }
    strong {
        padding: 0 rem(6);
        font: 500 rem(12) / rem(14) $f;
    }
    .icon-arrow-down {
        opacity: 0.8;
        transition: 400ms transform;
    }
    &:hover, &.popover-opened {
        background: sc(b, 0.1);
        color: sc(b);
        .icon-user-outline {
            background: sc(b, 0.9);
        }
    }
    &.popover-opened {
        &:hover {
            background: sc(b, 0.15);
        }
        .icon-arrow-down {
            transform: rotate(180deg);
        }
    }
}
.header-group-items {
    display: flex;
    align-items: center;
    --button-external-padding: #{rem(14)};
}
.sub-header-holder {
    width: 100%;
    padding: 0 rem($globalGap);
}
.sub-header {
    background: c(b);
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 rem(10) rem(10);
    padding: 0 rem(12);
}
.subheader-footer-holder {
    width: 100%;
    overflow: hidden;
    height: 0;
    transition: 150ms height;
    transform: translate3d(0, 0, 0);
    will-change: height;
    &.active {
        transition: 300ms height 444ms;
        height: rem(64);
    }
}
