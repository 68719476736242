@import "../../../assets/scss/magic";
$spriteHeight: 21264;
$flagSize: 48;
$gap: 20;
.flag-holder {
  width: rem(24);
  height: rem(24);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: rem(24);
  color: c(accent);
  &.s-medium {
    width: rem(18);
    height: rem(18);
    font-size: rem(18);
  }
  &.s-small {
    width: rem(14);
    height: rem(14);
    font-size: rem(14);
  }
  &.c-default {
    color: inherit;
  }
}
.flag {
  background: c(b, 0.1) url("../../../assets/media/flags.png") no-repeat 0 0 / 100%;
}
$flags: (
    afghanistan,
    alandislands,
    albania,
    algeria,
    americansamoa,
    andorra,
    angola,
    anguilla,
    antiguaandbarbuda,
    argentina,
    armenia,
    aruba,
    australia,
    austria,
    azerbaijan,
    azoresislands,
    bahamas,
    bahrain,
    balearicislands,
    bangladesh,
    barbados,
    basquecountry,
    belarus,
    belgium,
    belize,
    benin,
    bermuda,
    bhutan,
    bolivia,
    boliviaplurinationalstateof,
    bonaire,
    bonairesinteustatiusandsaba,
    bosniaandherzegovina,
    botswana,
    brazil,
    britishcolumbia,
    britishindianoceanterritory,
    britishvirginislands,
    brunei,
    bruneidarussalam,
    bulgaria,
    burkinafaso,
    burundi,
    caboverde,
    cambodia,
    cameroon,
    canada,
    canaryislands,
    capeverde,
    caymanislands,
    centralafricanrepublic,
    ceuta,
    chad,
    chile,
    china,
    chinesetaipei,
    christmasisland,
    cocosisland,
    colombia,
    comoros,
    congodemocraticrepublicofthe,
    congo,
    cookislands,
    corsica,
    costarica,
    cotedivoire,
    croatia,
    cuba,
    curacao,
    cyprus,
    czechrepublic,
    democraticrepublicofcongo,
    denmark,
    djibouti,
    dominica,
    dominicanrepublic,
    easttimor,
    ecuador,
    egypt,
    elsalvador,
    england,
    equatorialguinea,
    eritrea,
    estonia,
    ethiopia,
    europeanunion,
    falklandislands,
    falklandislandsmalvinas,
    faroeislands,
    fiji,
    finland,
    mayotte,
    newcaledonia,
    france,
    frenchguiana,
    frenchpolynesia,
    gabon,
    galapagosislands,
    gambia,
    georgia,
    germany,
    ghana,
    gibraltar,
    greece,
    greenland,
    grenada,
    guadeloupe,
    guam,
    guatemala,
    guernsey,
    guinea,
    guineabissau,
    guyana,
    haiti,
    hawaii,
    holysee,
    honduras,
    hongkong,
    hungary,
    iceland,
    india,
    indonesia,
    iran,
    iranislamicrepublicof,
    iraq,
    ireland,
    isleofman,
    israel,
    italy,
    ivorycoast,
    jamaica,
    japan,
    jersey,
    jordan,
    kazakhstan,
    kenya,
    kiribati,
    korea,
    koreademocraticpeoplesrepublicof,
    korearepublicof,
    kosovo,
    kuwait,
    kyrgyzstan,
    laopeoplesdemocraticrepublic,
    laos,
    latvia,
    lebanon,
    lesotho,
    liberia,
    libya,
    liechtenstein,
    lithuania,
    luxembourg,
    macao,
    macedoniatheformeryugoslavrepublicof,
    madagascar,
    madeira,
    malawi,
    malaysia,
    maldives,
    mali,
    malta,
    marshallisland,
    marshallislands,
    martinique,
    mauritania,
    mauritius,
    melilla,
    mexico,
    micronesia,
    micronesiafederatedstatesof,
    moldovarepublicof,
    moldova,
    monaco,
    mongolia,
    montenegro,
    montserrat,
    morocco,
    mozambique,
    myanmar,
    namibia,
    nauru,
    nepal,
    netherlands,
    newzealand,
    nicaragua,
    niger,
    nigeria,
    niue,
    norfolkisland,
    northencyprus,
    northernmarianaislands,
    northernmarianasislands,
    northkorea,
    norway,
    oman,
    orkneyislands,
    ossetia,
    otan,
    pakistan,
    palau,
    palestine,
    palestinestateof,
    panama,
    papuanewguinea,
    paraguay,
    peru,
    philippines,
    pitcairnislands,
    pitcairn,
    poland,
    portugal,
    puertorico,
    qatar,
    rapanui,
    republicofmacedonia,
    republicofthecongo,
    reunion,
    romania,
    russia,
    russianfederation,
    rwanda,
    sabaisland,
    sahrawiarabdemocraticrepublic,
    saintpierreandmiquelon,
    saintbarthelemy,
    saintmartinfrenchpart,
    sainthelenaascensionandtristandacunha,
    unitedkingdom,
    saintkittsandnevis,
    saintlucia,
    saintvincentandthegrenadines,
    samoa,
    sanmarino,
    saotomeandprincipe,
    sardinia,
    saudiarabia,
    scotland,
    senegal,
    serbia,
    seychelles,
    sicily,
    sierraleone,
    singapore,
    sinteustatius,
    sintmaarten,
    sintmaartendutchpart,
    slovakia,
    slovenia,
    solomonislands,
    somalia,
    somaliland,
    southafrica,
    southkorea,
    southsudan,
    spain,
    srilanka,
    stbarts,
    stlucia,
    stvincentandthegrenadines,
    sudan,
    suriname,
    svalbardandjanmayen,
    svalbardandjaymayen,
    swaziland,
    sweden,
    switzerland,
    syria,
    syrianarabrepublic,
    taiwan,
    taiwanprovinceofchina,
    tajikistan,
    tanzaniaunitedrepublicof,
    tanzania,
    thailand,
    tibet,
    timorleste,
    togo,
    tokelau,
    tonga,
    transnistria,
    trinidadandtobago,
    tubalu,
    tunisia,
    turkey,
    turkmenistan,
    turksandcaicos,
    turksandcaicosislands,
    tuvalu,
    uganda,
    ukraine,
    unitedarabemirates,
    unitednations,
    usa,
    unitedstatesofamerica,
    uruguay,
    uzbekistn,
    uzbekistan,
    vanuatu,
    vaticancity,
    venezuela,
    venezuelabolivarianrepublicof,
    vietnam,
    virginislands,
    virginislandsbritish,
    virginislandsus,
    wales,
    wallisandfutuna,
    westernsahara,
    yemen,
    zambia,
    zimbabwe,
);
@for $i from 1 through length($flags) {
    .flag-#{nth($flags, $i)} {
        $position: ($flagSize + $gap) * $i;
        background-position: 0 #{$position / ($spriteHeight - $flagSize) * 100  + '%'};
    }
}
// use this - https://instantsprite.com
