@import "../../../assets/scss/magic";
.information-message-holder {
    width: 100%;
    display: flex;
    align-items: center;
    padding: rem(16) rem(14);
    border-radius: rem(100);
    border: rem(1) dashed c(confirm);
    &.c-jaffa {
        @include over(confirm, jaffa);
    }
    &:not(:first-child) {
        margin: rem(36) 0 0;
    }
    > li {
        flex-shrink: 0;
        padding: 0 rem(6);
        &:nth-child(2) {
            flex: auto;
        }
        > .icon {
            color: c(confirm);
            border-radius: 100%;
        }
    }
    h3 {
        font: 700 rem(16) / rem(20) $f;
        margin: 0 0 rem(4);
        text-transform: capitalize;
    }
    p {
        font: 500 rem(12) / rem(16) $f;
        color: sc(b, 0.7);
        span {
            display: -webkit-box;
            color: sc(b);
            &.information-message-footnote {
                margin: rem(12) 0 0;
                font-size: rem(10);
                color: sc(b, 0.6);
            }
        }
    }
    &.a-compact {
        flex-direction: column;
        align-items: center;
        padding: rem(30);
        border-radius: rem(10);
        text-align: center;
        position: sticky;
        top: 0;
        > li {
            padding: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            > .icon {
                background: c(b);
                width: rem(120);
                height: rem(120);
                box-shadow: inset 0 0 0 rem(120) c(confirm, 0.05);
            }
            + li {
                margin: rem(24) 0 0;
            }
        }
        h3 {
            font-size: rem(18);
            margin: 0 0 rem(8);
        }
    }
}
