@import "../../../../assets/scss/magic";
.add-event-button-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: rem(12) rem(12) rem(10) rem(10);
  background: c(confirm, 0.05);
  border: rem(1) dashed c(confirm);
  &.span-2 {
    border-radius: rem(100);
    padding: rem(18) 0;
  }
}
