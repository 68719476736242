@import "../../../../../assets/scss/magic";
.file-input-holder {
    position: relative;
    width: 100%;
    > li {
        position: relative;
        z-index: 0;
        &:nth-child(2) {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
        }
    }
    input {
        &[type="file"] {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            opacity: 0;
            &::-webkit-file-upload-button {
                visibility: hidden;
            }
        }
    }
}
.fi-action-holder {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: rem(42);
    &.fi-left {
        width: rem(24);
        left: rem(15);
        text-transform: uppercase;
        font: 700 rem(5) / rem(10) $f;
    }
    &.fi-right {
        right: rem(8);
        color: sc(b, 0.5);
    }
}
