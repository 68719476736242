@import "../../../assets/scss/magic";
.loader-holder {
    width: 100%;
    height: 100%;
    display: flex;
    padding: rem(36);
    --size: 1;
    --loader-circle-background: #{c(b)};
    &.s-small {
        padding: 0;
        --size: 0.5;
    }
    &.splash-blur-holder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        grid-area: main;
        border-radius: rem(8) rem(8) 0 0;
        display: flex;
        backdrop-filter: blur(rem(4));
        transition: 400ms opacity, 400ms visibility;
        &:not(.active) {
            opacity: 0;
            visibility: hidden;
        }
    }
}
.loader {
    width: rem(60);
    height: rem(60);
    border-radius: 100%;
    margin: auto;
    color: c(primary);
    font-size: rem(48);
    line-height: rem(48);
    position: relative;
    transform: scale(0);
    animation: loader 300ms forwards;
    @keyframes loader {
        to {
            transform: scale(var(--size));
        }
    }
}
.loader-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader-circle {
    background: var(--loader-circle-background);
    border-radius: 100%;
    animation: 500ms loader-circle 300ms forwards;
    @keyframes loader-circle {
        100% {
            transform: scale(1.2);
        }
    }
}
.loader-icon {
    animation: 500ms rotate 1s infinite linear;
    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }
    &:before {
        display: block;
        animation: loader-icon-position 300ms 400ms forwards;
        @keyframes loader-icon-position {
            to {
                transform: translateY(-rem(12));
            }
        }
    }
    &:nth-child(2) {
        animation-duration: 400ms;
        &:before {
            animation-delay: 600ms;
        }
    }
}
