@import "magic";
* {
  margin: 0;
  border: 0;
  padding: 0;
  outline: 0 !important;
  border-radius: 0;
  background: none;
  vertical-align: top;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-tap-highlight-color: transparent;
  &, &:before, &:after {
    box-sizing: border-box;
  }
}
a {
  color: inherit;
  text-decoration: none !important;
  transition: 300ms color, 300ms background;
}
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
img, svg {
  display: block;
  max-width: 100%;
}
button,
input,
optgroup,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  font: inherit;
  appearance: none;
  display: block;
  color: inherit;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
  &::-ms-clear {
    display: none;
  }
  @include placeholder {
    opacity: 1;
    font-family: inherit;
  }
}
li {
  display: block;
}
.ellipsis-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}
.pointer-events-none {
  pointer-events: none;
}
.hidden {
    opacity: 0 !important;
    visibility: hidden;
    pointer-events: none;
}
.cursor-pointer {
  cursor: pointer;
}
.disabled {
    pointer-events: none;
    opacity: 0.5;
    transition: 400ms opacity;
}
::selection {
  background: c(primary, 0.1);
  color: c(primary);
}
@include not-retina {
  * {
    scrollbar-width: thin;
    scrollbar-color: map-get($scrollbar, 'thumbBackground') map-get($scrollbar, 'thumbBackground');
  }
  ::-webkit-scrollbar {
    width: map-get($scrollbar, 'size');
    height: map-get($scrollbar, 'size');
    &-button, &-corner {
      display: none;
    }
    &-thumb {
      border: map-get($scrollbar, 'space') solid transparent;
      border-radius: map-get($scrollbar, 'size');
      background: map-get($scrollbar, 'thumbBackground') content-box;
      &:hover, &:active {
        background-color: map-get($scrollbar, 'thumbActiveBackground');
      }
    }
    &-track {
      background: transparent;
    }
  }
}
.guide {
  margin: 0 auto;
  width: 100%;
  transition: 400ms width;
  .streamer-page & {
    width: rem(1320);
  }
}
