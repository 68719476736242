@import "../../../assets/scss/magic";
.rdrCalendarWrapper {
    align-self: center;
    display: flex;
    flex-direction: column;
    font-size: rem(14);
    padding: rem(36);
    * {
        $t: 200ms;
        transition: $t background, $t border-color, $t opacity;
    }
}
$gap: 8;
$height: 28;
.rdrMonthAndYearWrapper {
    display: grid;
    grid-template-columns: auto 1fr auto;
    position: relative;
    z-index: 10;
    .rdrDateRangeWrapper & {
        margin: 0 0 -rem(30);
        .rdrMonthAndYearPickers {
            color: transparent;
        }
    }
}
.rdrMonthAndYearPickers {
    pointer-events: none;
}
.rdrMonthName, .rdrMonthAndYearPickers {
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem(30);
    font: 500 rem(16) / rem(30) $f;
    color: sc(b);
}
.rdrMonths {
    &.rdrMonthsHorizontal {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: rem(48);
    }
}
.rdrWeekDays {
    margin: rem(36) 0 rem(24);
    color: c(primary);
    > span {
        position: relative;
        font-size: 0;
        text-align: center;
        &::first-letter {
            font: 700 rem(12) / rem(14) $f;
        }
    }
}
.rdrMonth {
    > div {
        &:not(.rdrMonthName) {
            display: grid;
            grid-template-columns: repeat(7, rem($height));
            grid-column-gap: rem($gap*2);
            grid-row-gap: rem($gap*2+2);
        }
    }
}
.rdrDay {
    position: relative;
    font: inherit;
    cursor: pointer;
}
.rdrDayNumber {
    display: block;
    position: relative;
    span {
        color: sc(b);
    }
}
.rdrDayDisabled {
    cursor: not-allowed;
}
.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge,
.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
    pointer-events: none;
}
.rdrDateRangePickerWrapper {
    display: flex;
    .rdrInputRanges {
        .rdrInputRange {
            &:last-child {
                @at-root {
                    .disable-days-starting-today#{&} {
                        display: none;
                    }
                }
            }
            &:first-child {
                @at-root {
                    .disable-days-up-to-today#{&} {
                        display: none;
                    }
                }
            }
        }
    }
}
.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
    color: ch(primary) !important;
}
.rdrDefinedRangesWrapper {
    display: flex;
    flex-direction: column;
    width: rem(240);
    border-right: rem(1) solid sc(b, 0.08);
    padding: rem(8) 0;
    color: sc(b, 0.8);
}
.rdrStaticRanges {
    width: 100%;
    padding: 0 0 rem(8);
    button {
        width: 100%;
        height: rem(45);
        font: rem(13) / rem(15) $f;
        cursor: pointer;
        transition: 300ms color, 300ms font-weight, 300ms background;
        text-transform: capitalize;
        padding: 0 rem(24);
        text-align: start;
        &:hover {
            color: c(primary);
            background: c(primary, 0.05);
        }
        &.rdrStaticRangeSelected {
            font-weight: 500;
        }
    }
}
.rdrInputRanges {
    width: 100%;
    border-top: rem(1) solid sc(b, 0.08);
    padding: rem(12) 0;
}
.rdrInputRange {
    width: 100%;
    display: flex;
    align-items: center;
    padding: rem(6) rem(12);
    font: rem(12) / rem(14) $f;
    input {
        margin: 0 rem(12);
        height: rem(36);
        width: rem(48);
        border-radius: rem(36);
        text-align: center;
        background: sc(b, 0.04);
        color: sc(b, 0.75);
        font: rem(14) $f;
        transition: 300ms background;
        &:focus {
            background: sc(b, 0.06);
        }
    }
}
.rdrStaticRange {
    font-size: inherit;
}
.rdrNextPrevButton {
    width: rem(30);
    height: rem(30);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: c(primary, 0.1);
    transition: color 400ms, background 400ms, opacity 400ms;
    color: c(primary);
    border-radius: 100%;
    font: rem(18) / 1 'icomoon';
    &:hover {
        background: c(primary);
        color: sc(primary);
    }
    i {
        display: none;
    }
    &:first-child {
        &:before {
            content: "\e91e";
        }
    }
    &:last-child {
        &:before {
            content: "\e91d";
        }
    }
}
.rdrDay {
    height: rem($height);
    text-align: center;
    &:before {
        content: "";
        display: block;
        position: absolute;
        top: -rem($gap);
        left: -rem($gap);
        right: -rem($gap);
        bottom: -rem($gap);
    }
}
.rdrDayNumber {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
        .rdrDayToday & {
            &:after {
                content: '';
                display: block;
                position: absolute;
                bottom: -rem(4);
                left: 50%;
                transform: translate(-50%, 0);
                width: rem(4);
                height: rem(4);
                border-radius: 100%;
                background: c(primary);
            }
        }
    }
}
.rdrDayToday .rdrInRange ~ .rdrDayNumber span:after,
.rdrDayToday .rdrStartEdge ~ .rdrDayNumber span:after,
.rdrDayToday .rdrEndEdge ~ .rdrDayNumber span:after,
.rdrDayToday .rdrSelected ~ .rdrDayNumber span:after {
    background: c(b);
}
.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: sc(primary, 0.85);
}
.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
    background: currentColor;
    position: absolute;
    top: -rem($gap);
    left: -rem($gap);
    right: -rem($gap);
    bottom: -rem($gap);
}
.rdrStartEdge {
    border-top-left-radius: rem($height);
    border-bottom-left-radius: rem($height);
    left: -rem($gap);
}
.rdrEndEdge {
    border-top-right-radius: rem($height);
    border-bottom-right-radius: rem($height);
    right: -rem($gap);
}
.rdrSelected {
    border-radius: rem($height);
}
.rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge {
    border-top-left-radius: rem($height);
    border-bottom-left-radius: rem($height);
    left: -rem($gap);
}
.rdrDayEndOfMonth .rdrInRange, .rdrDayEndOfMonth .rdrStartEdge, .rdrDayEndOfWeek .rdrInRange, .rdrDayEndOfWeek .rdrStartEdge {
    border-top-right-radius: rem($height);
    border-bottom-right-radius: rem($height);
    right: -rem($gap);
}
.rdrDayStartOfMonth .rdrDayInPreview, .rdrDayStartOfMonth .rdrDayEndPreview, .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayStartOfWeek .rdrDayEndPreview {
    border-top-left-radius: rem($height);
    border-bottom-left-radius: rem($height);
    border-left-width: rem(1);
    left: -rem($gap);
}
.rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview {
    border-top-right-radius: rem($height);
    border-bottom-right-radius: rem($height);
    border-right-width: rem(1);
    right: -rem($gap);
}
.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {
    background: c(b, 0.09);
    position: absolute;
    top: -rem($gap);
    left: -rem($gap);
    right: -rem($gap);
    bottom: -rem($gap);
    pointer-events: none;
    border: 0 solid currentColor;
    z-index: 1;
}
.rdrDayStartPreview {
    border-top-width: rem(1);
    border-left-width: rem(1);
    border-bottom-width: rem(1);
    border-top-left-radius: rem($height);
    border-bottom-left-radius: rem($height);
    left: -rem($gap);
}
.rdrDayInPreview {
    border-top-width: rem(1);
    border-bottom-width: rem(1);
}
.rdrDayEndPreview {
    border-top-width: rem(1);
    border-right-width: rem(1);
    border-bottom-width: rem(1);
    border-top-right-radius: rem($height);
    border-bottom-right-radius: rem($height);
    right: -rem($gap);
}
.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
    content: '';
    border: rem(1) solid currentColor;
    border-radius: rem($height);
    position: absolute;
    top: -rem($gap);
    bottom: -rem($gap);
    left: -rem($gap);
    right: -rem($gap);
    background: transparent;
}
.rdrDayPassive {
    pointer-events: none;
    .rdrDayNumber {
        span {
            color: sc(b, 0.15);
        }
    }
}
.rdrDayDisabled {
    background-color: sc(b, 0.03);
    border-radius: 100%;
    .rdrDayNumber {
        span {
            color: sc(b, 0.3);
        }
    }
}
.rdrDayDisabled .rdrInRange,
.rdrDayDisabled .rdrStartEdge,
.rdrDayDisabled .rdrEndEdge,
.rdrDayDisabled .rdrSelected,
.rdrDayDisabled .rdrDayStartPreview,
.rdrDayDisabled .rdrDayInPreview,
.rdrDayDisabled .rdrDayEndPreview {
    filter: grayscale(100%) opacity(60%);
}
.rdrDayPassive .rdrInRange,
.rdrDayPassive .rdrStartEdge,
.rdrDayPassive .rdrEndEdge,
.rdrDayPassive .rdrSelected,
.rdrDayPassive .rdrDayStartPreview,
.rdrDayPassive .rdrDayInPreview,
.rdrDayPassive .rdrDayEndPreview {
    display: none;
}

.rdrDateDisplayWrapper {
    display: none;
}
