@import "../../assets/scss/magic";
.cart-item-holder {
    width: 100%;
    @include gradient;
    border-radius: rem(11);
    padding: rem(1);
    transform: translate3d(0, 0, 0);
}
.card-item-content {
    border-radius: rem(10);
    position: relative;
    padding: rem(24);
    background: c(b);
}
.cart-item-close-holder {
    position: absolute;
    left: 100%;
    top: 0;
    border: rem(1) dashed c(peach);
    border-radius: 100%;
    background: c(b);
    transform: translate(-70%, -30%);
}
.cart-teams {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    overflow: hidden;
    font: 500 rem(18) / rem(20) $f;
    align-items: center;
    > li {
        overflow: hidden;
        &:first-child {
            text-align: end;
        }
    }
    small {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        font: 500 rem(8) / rem(12) $f;
        text-transform: uppercase;
        margin: 0 rem(20);
        color: sc(b, 0.5);
    }
}
.cart-price-details-holder {
    width: 100%;
    display: flex;
    align-items: center;
    > li {
        + li {
            margin: 0 0 0 rem(16);
        }
        &:last-child {
            flex: auto;
            text-align: end;
        }
    }
}
.cart-price {
    min-width: rem(60);
    border-radius: rem(60);
    display: block;
    text-align: center;
    background-image: linear-gradient(135deg, c(accent) 0%, #ec4f48 100%);
    color: sc(accent);
    font: 500 rem(16) / rem(60) $f;
    padding: 0 rem(12);
}
.cart-details-holder {
    font: rem(11) / rem(17) $f;
    color: sc(b, 0.5);
    strong {
        display: inline-block;
        color: c(peach);
    }
}
.cart-dropdown-holder {
    max-width: rem(280);
    width: 100%;
}
.cart-location-info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: sc(b, 0.5);
    font: rem(12) / rem(14) $f;
    margin: rem(14) 0;
}
.live-preview-holder {
    width: 100%;
    margin: rem(20) 0 0;
}
.live-preview-title {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto 1fr;
    align-items: center;
    cursor: pointer;
    grid-column-gap: rem(10);
    transition: 400ms color, 400ms opacity;
    font: 700 rem(10) $f;
    letter-spacing: rem(0.3);
    text-transform: uppercase;
    &:hover {
        color: c(confirm);
    }
    .icon {
        color: c(confirm);
        background: c(confirm, 0.1);
        width: rem(28);
        height: rem(28);
        border-radius: 100%;
    }
    &:after {
        content: '';
        display: block;
        width: 100%;
        border-top: rem(1) dashed c(confirm, 0.4);
    }
    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}
