@import "../../../assets/scss/magic";
.tag-c {
    padding: 0 rem(14);
    font: 500 rem(11) / rem(28) $f;
    border-radius: rem(36);
    background: c(warning, 0.05);
    color: c(warning);
    margin: 0 rem(8) rem(8) 0;
    transition: 400ms color, 400ms background;
    .table & {
        margin: 0 rem(8) 0 0;
    }
    .justify-end & {
        margin: 0 0 0 rem(8);
    }
    &.c-primary {
        @include over(warning, primary);
    }
    &.c-hero {
        @include over(warning, hero);
    }
    &.c-accent {
        @include over(warning, accent);
    }
    &.c-brand {
        @include over(warning, brand);
    }
    &.c-danger {
        @include over(warning, danger);
    }
    &.c-confirm {
        @include over(warning, confirm);
    }
    &.c-jaffa {
        @include over(warning, jaffa);
    }
    &.clipped {
        background: c(b);
        &.clickable {
            cursor: pointer;
            &:hover {
                background: c(warning);
                color: white;
            }
        }
    }
}
