@import "../../../assets/scss/magic";
.user-role {
    width: 100%;
    display: grid;
    //padding-top: 13px;
    grid-template-columns: 1fr auto 1fr;
    align-items: flex-start;
    grid-column-gap: rem(24);
&.interactive {
     grid-template-columns: 1fr auto 1fr auto;
 }
> li {
> small {
    display: flex;
    height: rem(42);
    width: rem(30);
    align-items: flex-end;
    justify-content: flex-end;
    font: rem(14) / rem(16) $f;
    opacity: 0.3;
}
}
}
.teams-actions-holder {
    display: flex;
    align-items: flex-end;
    height: rem(55);
}

.dropdown-item {
    width: 100%;
    padding: 0 rem(20);
    cursor: pointer;
    display: flex;
    font: rem(14) / rem(18) $f;
    &.virtualized {
        position: absolute;
        left: 0;
    }
    &.sticky {
        background: c(b);
        position: sticky;
        top: 0;
        z-index: 1;
    }
    > ul {
        width: 100%;
        height: rem(46);
        display: grid;
        grid-template-columns: 1fr rem(7);
        grid-column-gap: rem(12);
        transition: 400ms color;
        > li {
            display: flex;
            align-items: center;
            &:first-child {
                overflow: hidden;
            }
            &:last-child {
                justify-content: center;
            }
        }
        .icon {
            transform: scale(0);
            transition: 400ms transform, 400ms color;
            color: c(primary);
            &.icon-plus {
                color: c(confirm);
            }
            &.icon-trash {
                color: sc(b, 0.3);
                &:hover {
                    color: c(danger);
                }
            }
        }
    }
    &.active:not(.multi-select):not(.deselect):not(.with-action) {
        pointer-events: none;
    }
    &:hover, &.active:not(.multi-select) {
        color: c(primary);
    }
    &:hover .icon, &.active .icon:not(.icon-trash) {
        transform: scale(1);
    }
    &.suggestion {
        &:hover, &.active {
            color: c(confirm);
        }
    }
}
