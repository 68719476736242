@import "../../assets/scss/magic";
#root {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto 1fr;
    grid-template-areas: "header header header" "asideLeft main asideRight";
    grid-column-gap: rem($globalGap);
    grid-row-gap: rem($globalGap);
    position: relative;
    overflow: hidden;
    .fit-gaps & {
        grid-column-gap: 0;
        grid-row-gap: 0;
    }
    .streamer-page & {
        grid-row-gap: 0;
    }
    header {
        grid-area: header;
        position: relative;
        z-index: 20;
    }
    > * {
        &:only-child {
            grid-area: main;
        }
    }
    main {
        grid-area: main;
        position: relative;
        z-index: 0;
        overflow-x: hidden;
        overflow-y: auto;
        border-radius: rem(8) rem(8) 0 0;
        display: flex;
        align-items: flex-start;
        transform: translate3d(0, 0, 0);
        will-change: scroll-position;
        .fit-gaps & {
            border-radius: 0;
        }
    }
    aside {
        &.p-left {
            grid-area: asideLeft;
        }
        &.p-right {
            grid-area: asideRight;
        }
    }
}
