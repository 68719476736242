@import "../../../assets/scss/magic";
.top-menu-holder {
    --top-menu-holder: #{rem(48)};
    display: grid;
    width: 100%;
    grid-template-columns: 1fr auto 1fr;
    height: var(--top-menu-holder);
    position: relative;
    z-index: 1;
    > li {
        display: flex;
        align-items: center;
        &:nth-child(2) {
            padding: 0 rem(6);
        }
        &:last-child {
            justify-content: flex-end;
        }
    }
    .divider {
        width: rem(1);
        height: rem(10);
        background: c(primary, 0.1);
        margin: 0 rem(10);
        &:first-child {
            display: none;
        }
    }
}
.sub-header-nav {
    display: flex;
    > li {
        margin: 0 rem(20);
        cursor: pointer;
        font: 500 rem(12) / var(--top-menu-holder) $f;
        color: sc(b, 0.7);
        transition: 400ms color, 400ms opacity;
        overflow: hidden;
        position: relative;
        letter-spacing: rem(0.3);
        &:before {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: rem(4);
            background: c(primary);
            transition: 400ms transform;
            border-radius: rem(4) rem(4) 0 0;
        }
        &:hover, &.active {
            color: c(primary);
        }
        &.active, &.disabled {
            pointer-events: none;
        }
        &.disabled {
            opacity: 0.5;
        }
        &:not(.active) {
            &:before {
                transform: translateY(200%);
            }
        }
    }
}
