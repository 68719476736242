@import "../../../assets/scss/magic";
.tabs-holder {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    --tabs-head-content-li-font: #{500 rem(14) / rem(16) $f};
    --tab-head-box-background: #{c(primary)};
    --tab-head-box-bottom: #{0};
    --tab-head-box-top: auto;
    --tab-head-box-height: #{rem(4)};
    &.c-accent {
        --tab-head-box-background: #{c(accent)};
        > .tab-head {
            @include over(primary, accent);
        }
    }
    &.c-jaffa {
        --tab-head-box-background: #{c(jaffa)};
        > .tab-head {
            @include over(primary, jaffa);
        }
    }
    &.c-buckthron {
        --tab-head-box-background: #{c(buckthron)};
        > .tab-head {
            @include over(primary, buckthron);
        }
    }
    &.a-default, &.a-inline {
        --tabs-head-height: #{rem(64)};
    }
    &.a-default {
        --tabs-head-padding: #{0 rem(24)};
        --tabs-head-height: #{rem(64)};
        --tabs-head-content-border-bottom-width: #{rem(1)};
        --tab-content-padding: #{rem(24)};
    }
    &.a-glass {
        --tabs-head-height: #{rem(56)};
        --tabs-head-content-li-font: #{500 rem(13) / rem(15) $f};
        --tab-head-box-background: #{c(b)};
        --tab-head-box-bottom: #{-rem(8)};
        --tab-head-box-top: 0;
        --tab-head-box-height: auto;
        --tab-content-padding: #{rem(36)};
    }
    &.a-inline {
        overflow: visible;
        --tab-content-padding: 0;
        --tab-item-custom-width: #{rem(200)};
        .overlay-body & {
            --tab-content-padding: #{rem(34)};
        }
    }
    &.a-glass & {
        position: sticky;
        top: 0;
        z-index: 10;
        background: c(b);
    }
}
.tab-head {
    padding: var(--tabs-head-padding);
    flex-shrink: 0;
    text-transform: capitalize;
    > ul {
        width: 100%;
        display: flex;
        align-items: center;
        > li {
            flex-shrink: 0;
            &:first-child {
                flex: auto;
            }
            &:nth-child(2) {
                margin: 0 0 0 rem(24);
                background: c(b);
                border-radius: 100%;
            }
        }
    }
    .a-inline > & {
        border-bottom: rem(1) solid c(primary);
        padding: 0 rem(30);
        position: sticky;
        top: 0;
        background: c(b);
        z-index: 10;
        color: sc(b, 0.6);
    }
}
.tabs-head-content {
    display: flex;
    position: relative;
    height: var(--tabs-head-height);
    border-bottom: var(--tabs-head-content-border-bottom-width, 0) solid c(primary);
    li {
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        cursor: pointer;
        transition: 400ms color, 400ms transform, 400ms width;
        font: var(--tabs-head-content-li-font);
        position: relative;
        z-index: 1;
        &:hover, &.active {
            color: c(primary-hover);
        }
        &.active {
            pointer-events: none;
        }
        &.box {
            position: absolute;
            bottom: var(--tab-head-box-bottom);
            left: 0;
            background: var(--tab-head-box-background);
            border-radius: rem(8) rem(8) 0 0;
            pointer-events: none;
            z-index: 0;
            top: var(--tab-head-box-top);
            height: var(--tab-head-box-height);
            will-change: transform, width;
        }
        &:nth-last-child(2):first-child {
            pointer-events: none;
            .a-glass & {
                &, ~ li {
                    width: rem(220) !important;
                }
            }
        }
        .icon {
            margin: 0 rem(4) 0 0;
            flex-shrink: 0;
        }
    }
}
.tab-title-holder {
    display: flex;
    align-items: center;
    max-width: 100%;
    overflow: hidden;
    padding: rem(3);
    p {
        flex: auto;
    }
    small {
        flex-shrink: 0;
        min-width: rem(20);
        padding: 0 rem(4);
        height: rem(20);
        display: flex;
        justify-content: center;
        align-items: center;
        font: 700 rem(10) / rem(12) $f;
        border: rem(1) solid c(primary);
        color: c(primary-hover);
        border-radius: rem(20);
        margin: 0 0 0 rem(8);
        transition: 400ms color, 400ms background-color, 400ms box-shadow;
        &.active {
            box-shadow: 0 0 0 rem(2) c(primary);
            border-color: c(primary);
        }
        .active > & {
            background: c(primary);
            color: sc(primary);
            &.active {
                box-shadow: 0 0 0 c(primary);
            }
        }
    }
}
.tab-body {
    width: 100%;
    flex: auto;
    overflow: hidden;
    position: relative;
    background: c(b);
    border-radius: rem(8);
    > ul {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        transition: 400ms transform, 400ms height;
        will-change: transform, height;
        .overlay-body .tabs-holder & {
            align-items: stretch;
        }
        > li {
            flex-shrink: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            overflow-x: hidden;
            overflow-y: auto;
            scroll-behavior: smooth;
            will-change: scroll-position;
            position: relative;
            &.as-grid {
                display: grid;
                grid-template-rows: 1fr auto;
                grid-template-columns: 100%;
                .tab-content {
                    overflow-x: hidden;
                    overflow-y: auto;
                    padding: var(--tab-content-padding);
                }
            }
            .tab-content {
                width: 100%;
                flex: auto;
            }
            .tab-footer-button {
                background: c(b);
                padding: rem(16) var(--tab-content-padding);
                width: 100%;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: calc(var(--tab-content-padding) / 2);
                color: sc(b, 0.6);
                --button-custom-size: #{rem(38)};
                --button-custom-font-size: #{rem(12)};
                > * {
                    &:only-child {
                        grid-column: span 2;
                    }
                }
            }
            &:not(.c-financial-report):not(.c-user-management):not(.as-grid) {
                padding: var(--tab-content-padding);
            }
        }
    }
    .a-glass > &, .a-inline > & {
        > ul {
            position: static;
            align-items: flex-start;
        }
    }
    .a-inline > & {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .a-glass > & {
        > ul {
            max-height: calc(100vh - #{rem(80)} - var(--header-height) * 2);
            > li {
                max-height: inherit;
            }
        }
    }
}
