@import "../../../assets/scss/magic";
.overlay-c-holder {
    position: fixed;
    top: var(--header-height);
    left: 0;
    width: 100%;
    bottom: 0;
    overflow: hidden;
    z-index: 20;
}
.overlay-holder {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background: c(b);
    display: flex;
    flex-direction: column;
    width: rem(680);
    box-shadow: -rem(5) 0 rem(6) rgba(black, 0.1);
    animation-duration: 300ms;
    transform: translate3d(110%, 0, 0);
    &.t-usercart {
        width: rem(600);
    }
    &.t-eventform {
        width: rem(800);
    }
}
.overlay-footer, .overlay-head {
    height: rem(66);
    z-index: 2;
    flex-shrink: 0;
    padding: 0 rem(34);
    position: relative;
}
.overlay-body {
    flex: auto;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: rem(34);
    z-index: 0;
    position: relative;
    &.a-fit {
        padding: 0;
    }
}
.overlay-head {
    box-shadow: 0 rem(1) 0 sc(b, 0.06);
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2 {
        font: 500 rem(16) / rem(24) $f;
        text-transform: capitalize;
    }
    h3 {
        font: rem(12) / rem(16) $f;
        color: sc(b, 0.6);
    }
}
.overlay-footer {
    box-shadow: 0 -rem(1) 0 sc(b, 0.06);
    display: flex;
    align-items: center;
    color: sc(b, 0.7);
    > ul {
        width: 100%;
        display: flex;
        align-items: center;
        > li {
            display: flex;
            flex-shrink: 0;
            &:first-child {
                flex-direction: column;
                flex: auto;
            }
            &:last-child {
                align-items: center;
                > * {
                    &.btn {
                        min-width: rem(140);
                    }
                    + * {
                        margin: 0 0 0 rem(12);
                    }
                }
            }
        }
    }
    h2 {
        font: 500 rem(16) / rem(24) $f;
        color: sc(b);
    }
    h3 {
        font: 500 rem(12) / rem(16) $f;
        opacity: 0.6;
    }
}
.overlay-close-button {
    position: absolute;
    right: 100%;
    top: 0;
    width: rem(66);
    height: rem(66);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: rem(8) 0 0 rem(8);
    background: c(b);
    color: sc(b, 0.6);
    box-shadow: -rem(6) 0 rem(6) rgba(black, 0.1);
    margin: 0 -rem(1) 0 0;
}
