@import "../../../assets/scss/magic";
.pagination-holder {
  width: 100%;
  padding: 0 rem(24);
  height: rem(76);
  display: flex;
  align-items: center;
  font: 500 rem(12) / rem(14) $f;
  text-transform: lowercase;
  color: sc(b, 0.5);
  border-top: rem(1) solid sc(b, 0.05);
  position: sticky;
  bottom: 0;
  z-index: 10;
  background: c(b);
  > li {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    &:first-child {
      flex: auto;
      span {
        margin: 0 rem(4);
        opacity: 0.8;
      }
    }
  }
}
.pagination {
  display: flex;
  align-items: center;
  color: sc(b, 0.5);
  .divider {
    width: rem(1);
    height: rem(24);
    margin: 0 rem(15);
    background: sc(b, 0.1);
  }
  .widget-grid-holder & {
    .s-aside-opened & {
      button {
        &:not(.active) {
          &:not(.c-icon) {
            display: none;
          }
        }
      }
    }
  }
}
